import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const LinkWrapper = styled.div`
  a {
    display: inline-block;
    color: #5e6ff9;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
    }
  }
`;

export default function LinkComponent({ href, text }) {
  const navigate = useNavigate();

  // function handleRedirect() {
  //   navigate(href.replace(navigate, text), { replace: true });
  // }

  // onClick={handleRedirect}

  return (
    <LinkWrapper>
      <HashLink smooth to={href}>
        {text}
        <span>→</span>
      </HashLink>
    </LinkWrapper>
  );
}
