import React, { useState, useRef, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import "bootstrap/dist/css/bootstrap.min.css";
import IMAGES from "../Images";
import Terms from "./Terms.pdf";
import TermsIn from "../assets/terms/Terms-latest.pdf";
import Privacy from "../assets/terms/Privacy-latest.pdf";
import Recaptcha from 'react-google-invisible-recaptcha';
import {
  apiToken, sendOtp,
  verifiedEmail,
 customerOnboard
} from "../../helpers/dotenv";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./SignUp.module.css";
import { Loader } from "./Style";
// import { BsEyeSlash, BsEye } from "react-icons/bs";
import AlertComponent from "../shared/AlertComponent";
import { encryptPayload } from "../../helpers/EncryptDecrypt";
import styled from "styled-components";

/** Danger NOTE:
 * Whenever add new changes inside this signup page, please test after failer of send otp,
 * are we able to send latest updated states to sendOtp api, because captcha creating issues.
 */

// Otp Box
function OtpBox({onHide ,otp, setOtp, phone, show, setOtpBox, isEmailExist, handleClick}) {

  const handleChange = (otp) => {
    setOtp(otp);
  };

  // function handleCloseBox() {
  //   setOtpBox(false);
  // }
  return (
    <>
      <Modal
        className="my-modal1"
        id="modal1"
        size="lg"
        show={show}
        backdrop="static" 
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
        <Modal.Body>
        <ModalBody>
          <h2 style={{textAlign:"center"}}>OTP Verification</h2>
            <p style={{ fontFamily: "Poppins",textAlign:"center" }}>
              Please enter the one-Time Code to verify your account.
              <br />A one-Time code has been sent to {phone}
            </p>
          <OtpInput
            className="otp_input"
            inputType="tel"
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>&ensp;</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: "none",
              borderBottom: "2px solid grey",
              outline: "none",
            }}
          />
           </ModalBody>
        </Modal.Body>

        <Modal.Footer
          className="modal-footer"
          style={{
            "--bs-modal-footer-border-color": "white",
            "--bs-modal-footer-gap": "0",
            paddingBottom: "30px",
            fontFamily: "Poppins",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                borderredius: "20px",
                alignItems: "center",
                fontWeight: "bold",
                width: "100%",
                height: "7%",
              }}
              onClick={() => isEmailExist()}
              className="text-center"
              disabled={otp.length < 6}
            >
              Verify
            </Button>
            <div>
              <button
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecorationLine: "none",
                }}
                className="btn btn-link"
                onClick={handleClick}
              >
                Resend one-Time Code
              </button>
              {/* <p
                // onClick={handleCloseBox}
                style={{ fontSize: "medium", cursor: "pointer" }}
              >
                Entered a wrong Number?
              </p> */}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}


function ThankYouBox ({onBoard}) {
  return(
    <Modal
    id="modal4"
    size="md"
    show={onBoard}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body id="modal4" style={{justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  padding: "1.5rem"}}>
      {/* {messageError && ( */}
        <React.Fragment>
          <img src={IMAGES.check} alt="" />
          <p>Thank you for registering with us!</p>
            <p style={{ color:"green", textAlign:"center" }}>
            please verify your email address by clicking the link we've sent to
                  your inbox
            </p>
          <p style={{ textAlign: "center" }}>
          Hold on! We are redirecting you to <span style={{    color: "firebrick",textDecoration: "underline",}}>login page</span>
          </p>
          {/* <MinimalLoader /> */}
        </React.Fragment>
      {/* ) } */}
    </Modal.Body>
  </Modal>
  );
}



export default function SignUp(props) {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [customerId, setCustomerId] = useState();
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState(2);
  const [isChecked, setIsChecked] = useState(false);
  const [otpBox, setOtpBox] = useState(false);  // otpBox
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("in");
  const [privacy, setPrivacy] = useState("");
  const [emailExistsErr, setEmailExistsErr] = useState(false);
  const [onBoard, setOnBoard] = useState(false); // onboard message box
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [emailPrompt, setEmailPrompt] = useState("");
  const [passwordPrompt, setPasswordPrompt] = useState("");
  const [checked, setChecked] = useState(false);
  const [companyNamePrompt, setCompanyNamePrompt] = useState("");
  const [existingCompanyName, setExistingCompanyName] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [date, setDate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  
  const cleanInputBox = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCustomerId("");
    setCompanyName("");
    setRole("");
    setIsChecked(false);
    setPassword("");
    setCountry("");
    setShowPassword("");
    setChecked("");
  };


  function handleShowAlert(type, message) {
    setAlertType(type);
    setAlertMessage(message);
    setShowAlert(true);

    const timeoutId = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  
    // Clear the timeout when component unmounts or when needed
    return () => clearTimeout(timeoutId);
  }


  const validateForm = () => {
    if (
      firstname === "" ||
      lastname === "" ||
      phone === "" ||
      email === "" ||
      companyName === "" ||
      password === ""
    ) {
      handleShowAlert("error", "Please fill in all fields.");
      return false;
    } else {
      
    }

    if (country === "in") {
      if (phone.length !== 12) {
        handleShowAlert("error", `Please enter a valid number`);
        // handleShowAlert("error", `Please enter a valid number 1.  ${phone} len: ${phone.length}`);
        return false;
      }
    } else {
      if (phone === "" || phone.length <= 5 || phone.length >= 21) {
        handleShowAlert("error", "Please enter a valid number.");
        return false;
      }
    }

    const phoneRegex = 
    /\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{6,21}$/;


    if (phone  === "" || !phoneRegex.test(phone)) {
      console.log(`number number: ${phone}`);
      handleShowAlert("error", "Please enter a valid number.");
      return false;
    } 


    //password
    const validPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.*()_&])[A-Za-z\d@.*()_&]{8,15}$/;

    if (password === "" || !validPassword.test(password)) {
      setPasswordPrompt(
        "The password must contain 8-15 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character from the set @ . _ ( * ) &"
      );
      return false;
    } else {
      setPasswordPrompt("");
    }

    return true;
  };
  
  const linkStyle = {
    color: "#5e6ff9",
    textDecoration: "none",
  };

  const eyeStyle = {
    margin: "8px auto",
    width: "26px",
    position: "absolute",
    right: "20px",
    top: "26px",
    fill: "#808B96"
  };

  const redirectSignIN = () => {
    window.location.href = "https://portal.tubelightcommunications.com/sign-in";
  };

  const isApiWork = () => {
    const apiUrl = "https://geolocation-db.com/json/";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        return response.json();
      })
      .then((data) => {
        const ipAddress = data.IPv4;
        const date = new Date();

        // console.log("IP Address:", ipAddress);
        // console.log("Date:", date);

        setIpAddress(ipAddress);
        setDate(date);

        const requestBody = {
          ipAddress: ipAddress,
          date: date.toString(),
        };
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
  };

  function isEmailExist() {
    isApiWork();

    // onboard customer
    let requestBody = {
      newUser: true,
      tenantName: companyName.trim(),
      customerId: customerId,
      name: firstname.trim() + " " + lastname.trim(),
      password: password,
      email: email,
      mobileNumber: phone,
      balance: "10",
      role: role,
      company: companyName,
      country: country,
      ipAddress: ipAddress,
      date: date.toString(),
      region: 1,
      otp
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: encryptPayload(requestBody),
      // redirect: "follow",
    };
    fetch(customerOnboard, requestOptions)
      .then((response) => {

        if(response.ok) {
          setOtpBox(false);
          setOnBoard(true);
          setTimeout(() => {
            redirectSignIN();
          }, 6000);

        } else {
          
        handleShowAlert("error", "Invalid OTP, Please enter correct OTP");

          setTimeout(() => {
            setIsChecked(false);
            setShowReCAPTCHA(false);
          }, 2000);
        }
        
      })
      .catch((error) => {
        console.log("error", error);
        console.log("onboard failed");

        // for otp box
        setOtpBox(false);
          setTimeout(() => {
            setIsChecked(false);
            setShowReCAPTCHA(false);
          }, 2000);
      });
  }


  function validateCompanyName() {
    if (companyName === "") {
      return;
    }
    const companyNameRegex = /^(?=.*[A-Za-z])[A-Za-z0-9_,./()\s'&#+*-]+$/;
    if (!companyNameRegex.test(companyName)) {
      handleShowAlert(
        "error",
        `Company name field allowed characters include alphanumeric and these special characters: [_,./()'&#+*-].`
      );

      setExistingCompanyName(true);
      return;
    } else {
      // setEmailExistsErr(false);
      setExistingCompanyName(true);
    }

    fetch(`${process.env.REACT_APP_VALID_COMPANY_NAME}`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        // Authorization: `Bearer ${companyapiToken}`,
      },
      body: encryptPayload({
        companyName,
      }),
    })
      .then((data) => {

        if (data.status === 409) {
          setExistingCompanyName(true);
          setCompanyNamePrompt("Company Name Already Exists");
          return;
        } else {
          setCompanyNamePrompt("");
          setExistingCompanyName(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const sendOtpRequest = useCallback(async (data) => {
    // console.log(`captcha data: ${data}`);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${apiToken}`);
    myHeaders.append("Content-Type", "application/json");
  
    var formdata = {
      phone_number: `+${phone}`,
      email: `${email}`,
      time: Date.now()
    };
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ data: encryptPayload(formdata) }),
      redirect: "follow"
    };
    
    try {
      const response = await fetch(sendOtp, requestOptions);
      //status code mila
     // console.log(`status code------: ${JSON.stringify(response.status)}`);

      const Data = await response.json();
      //response milgaya.
      // console.log(`status code------: ${JSON.stringify(Data)}`);

      if (response.status === 200) {
        setOtpBox(true);
        handleShowAlert("info", `OTP sent to your mobile number`);
        recaptchaRef.current.reset();
      } 

      if (response.status === 400){
        handleShowAlert("info", `${Data.error}`);
        recaptchaRef.current.reset();
      }
      
    } catch (error) {
      console.error("Error:", error);
      handleShowAlert("error", "An error occurred while sending OTP.");
    }
  },[phone, email]) 
  
  

  
  function verifyEmail() {
    if (!email) {
      // Email is empty
      setEmailExistsErr(false); // Reset email exists error
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      // Email is in an invalid format
      setEmailExistsErr(true); // Reset email exists error
      setEmailPrompt("Invalid Email");
      return;
    } else {
      setEmailPrompt("");
      setEmailExistsErr(false);
    }

    // Email validation passed, make API call
    fetch(verifiedEmail, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        // Authorization: `Bearer ${emailapiToken}`,
      },
      body: encryptPayload({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 409) {
          setEmailExistsErr(true);
          setEmailPrompt("Email already Exists");
        } else {
          // setEmailPrompt("");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }


  const handleClick = (e) => {
    e.preventDefault();
    // console.log(`inside handleClick function ${phone}`);
    // console.log(`isFormValid : ${ validateForm()}`);
      if (validateForm()) {
    // console.log(`isFormValid inside before hitting api: ${ validateForm()}`);
        setIsLoading(false);
        recaptchaRef.current.execute();
        // sendOtpRequest();

      } else {
        setIsChecked(false);
        // console.log('Form validation failed.');
      }

  }
  

  

  const handleVisibilityClick = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      {showAlert && <AlertComponent type={alertType} message={alertMessage} />} 
      <OtpBox 
      show={otpBox} 
      onHide={() => { setIsChecked(false); setShowReCAPTCHA(false); setOtpBox(false) }}
      otp={otp} 
      setOtp={setOtp} 
      phone={phone} 
      setOtpBox={setOtpBox} 
      isEmailExist={isEmailExist}
      handleClick={handleClick}
      />
      {<ThankYouBox onBoard={onBoard}/>}
      <BoxWrapper>
      <div className={styles.signup_container}>
        <img className={styles["top-arrow"]} src={IMAGES.topicon} alt="" />
        <img className={styles["top-icon"]} src={IMAGES.toparrow} alt="" />
        <section className={styles["contact-flex-container"]}>
          <div className={styles["contact-left-image"]}>
            <a href="https://tubelightcommunications.com/"><img src={IMAGES.contact1} alt="" /></a>  
            <div className={styles.text_wrapper}>
                <h3 style={{ fontWeight: "bold" }}>Free Text Credits</h3>
                <p>No credit card required.</p>
                <h3 style={{ fontWeight: "bold" }}>
                  Programmable APIs and Tools
                </h3>
                <p>
                  SMS, WhatsApp, Voice, Verification,<br/>
                  Numbers and AI powered chatbots.
                </p>
                <h3 style={{ fontWeight: "bold" }}>
                  Quick and Easy Integration
                </h3>
                <p>Get started in minutes.</p>
            </div>
          </div>

          <div className={styles["contact-form"]}>
            <h1 className={styles["large-heading-1"]}>
              Get Started
            </h1>

            <p className={styles["input-type"]}>
              Already have an account ?{" "}
              <a
                href="https://portal.tubelightcommunications.com/sign-in"
                style={linkStyle}
              >
                <b>Login</b>
              </a>
            </p>
            <FormWrapper
              style={{ marginTop: "1rem" }}
              autoComplete="off"
              onSubmit={handleClick}
              disabled={isClicked}
              
            >
              <div className={styles["input-wrapper"]}>
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  className={styles["input-field"]}
                  value={firstname}
                  autoComplete="off"
                  placeholder="Enter Firstname"
                  onChange={(e) => setFirstName(e.target.value)}
                  maxLength={20}
                  required
                />
              </div>
              <div className={styles["input-wrapper"]}>
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  autoComplete="off"
                  className={styles["input-field"]}
                  value={lastname}
                  placeholder="Enter Lastname"
                  onChange={(e) => setLastName(e.target.value)}
                  maxLength={20}
                  required
                />
              </div>

              <div className={styles["input-wrapper"]}>
                <label htmlFor="companyname">Company Name</label>

                <input
                  type="text"
                  className={styles["input-field"]}
                  value={companyName}
                  placeholder="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  onBlur={validateCompanyName}
                  maxLength={50}
                  required
                />
                <span style={{ color: "red" }}>{companyNamePrompt}</span>
              </div>
              <div className={styles["input-wrapper"]}>
                <label htmlFor="mobile">Mobile</label>

                <PhoneInput
                  countryCodeEditable={false}
                  country={"in"}
                  enableSearch={true}
                  placeholder="Enter Number"
                  className={styles.country_phone}
                  value={phone}
                  onChange={(phone, country) => {
                    console.log(phone)
                    setPhone(phone);
                    // sendOtpRequest();
                    setCountry(country.countryCode);
                  }}
                  required
                />
              </div>
              <div
                style={{ gridColumn: "1/-1" }}
                className={styles["input-wrapper"]}
              >
                <label htmlFor="email">Email</label>

                <input
                  type="email"
                  className={styles["input-field"]}
                  value={email}
                  placeholder="Enter Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailPrompt("");
                  }}
                  onBlur={verifyEmail}
                  autoComplete="off"
                  required
                />
                <span style={{ color: "red" }}>{emailPrompt}</span>
              </div>
              <div
                style={{
                  gridColumn: "1/-1",
                  cursor: "pointer",
                  position: "relative",
                }}
                className={styles["input-wrapper"]}
              >
                <label htmlFor="password">
                  Password
                  <span style={{ fontSize: "12px", fontWeight: "lighter" }}>
                    {" "}
                    (Between 8-15 characters)
                  </span>{" "}
                </label>

                <input
                  type={showPassword ? "text" : "password"}
                  className={styles["input-field"]}
                  value={password}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordPrompt("");
                  }}
                  maxLength={15}
                  required
                />
                <span style={{ color: "red" }}>{passwordPrompt}</span>
                {/* {isValid ? 'Password is valid.' : 'Password is invalid.'} */}
                <span
                  className="material-symbols-outlined"
                  onClick={handleVisibilityClick}
                  style={{
                    padding: "0px",
                    marginLeft: "0px",
                    marginRight: "10px",
                  }}
                >
                  {showPassword ? (
                    // <BsEye style={eyeStyle} />
                    <svg xmlns="http://www.w3.org/2000/svg" style={eyeStyle} viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/></svg>
                  ) : (
                    // <BsEyeSlash style={eyeStyle} />
                    <svg xmlns="http://www.w3.org/2000/svg"  style={eyeStyle}viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z"/></svg>
                  )}
                </span>
              </div>

              <div className={styles.checkbox} type="checkbox">
                <FlexWrapper>
                <input
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                    if(validateForm()){
                      setShowReCAPTCHA(true);
                    } else {
                      setShowReCAPTCHA(false);
                      setIsChecked(false);
                    }
                  }}
                  type="checkbox"
                />{" "}
                <span style={{ fontSize: "14px" }}>
                  I agree to all the{" "}
                  <a
                    href={`${country === "in" ? TermsIn : TermsIn}`}
                    target="_blank"
                    style={linkStyle}
                  >
                    Terms of Services
                  </a>{" "}
                  and{" "}
                  <a
                    href={Privacy}
                    target="_blank"
                    onClick={() => setPrivacy(true)}
                    style={linkStyle}
                  >
                    Privacy Policy
                  </a>
                </span>
                <br></br>
                </FlexWrapper>
                <FlexWrapper>
                  {" "}
                  <input
                    checked={checked}
                    onChange={() =>setChecked(!checked)}
                    type="checkbox"
                  />{" "}
                  <span style={{ fontSize: "14px" }}>
                    I want to receive Tubelight communications news, trends,
                    offers or blogs by email.
                  </span>
                  </FlexWrapper>
                <br />
              </div>
              <div
                style={{
                  display: "grid",
                  flexDirection: "column",
                  width: "250px",
                  marginTop: "-2rem",
                }}
              >

                {showReCAPTCHA && (
                <Recaptcha 
                    ref={(ref) => recaptchaRef.current = ref} 
                    sitekey={recaptchaSiteKey}
                    onResolved={sendOtpRequest} 
                    // onloadCallback={recaptchaLoaded}
                />)}

                <Button
                  type="submit"
                  onClick={handleClick}
                  disabled={
                    !(isChecked && !(existingCompanyName || emailExistsErr))
                  }
                  className="btn btn-primary btn-md"
                  style={{
                    alignContent: "stretch",
                    backgroundColor: "#5E6FF9",
                    borderredius: "20px",
                    width: "250px",
                    display: "grid",
                    marginBottom: "4rem",
                    justifyContent: "center",
                    visibility: isLoading ? "hidden" : "visible",
                  }}
                  >
                  SignUp
                </Button>
                {isLoading && <Loader />}
              </div>
            </FormWrapper>
          </div>
        </section>
      </div>
      <img className={styles.bottom_image} src={IMAGES.bottom} alt="" />
      </BoxWrapper>
    </>
  );
}

const BoxWrapper = styled.div`
  position: relative;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: baseline;
  
`;

const ModalBody = styled.div`
  text-align: center;
   
  > div {
    justify-content: center;
    
  }
  p {
    font-size: 16px;
  }
  h2 {
    > div {
      justify-content: center;
    }
  }

  .otp_input input {
    width: 50px !important;
    height: 50px;

    @media (min-width: 768px) {
      width: 70px !important;
    }
  }
`;

const FormWrapper =styled.form`
 width: 100%;
@media (min-width: 634px) {
   max-width: 550px;

}


`;