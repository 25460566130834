import React, { useState, useEffect } from 'react'
import Header from './Header';
import './Header.css'

export default function HideNavbar() {
    // const [showNav, setShowNav] = useState(true);

    // useEffect(() => {
    //     let prevScrollpos = window.pageYOffset;
    //     window.addEventListener("scroll", () => {
    //       const currentScrollPos = window.pageYOffset;
    //       if (prevScrollpos > currentScrollPos) {
    //         setShowNav(true);
    //       } else {
    //         setShowNav(false);
    //       }
    //       prevScrollpos = currentScrollPos;
    //     });
    //     return () => {
    //       window.removeEventListener("scroll");
    //     };
    //   }, []);
    
    

//   function handleScroll() {
//     if(window.scrollY  > 100){
//         setVisible(false)
//     }else {
//         setVisible(true)
//     }
//   }

  return (
    <div>
        
      {/* <nav className={showNav ? "nav-show" : "nav-hide"}> */}
        <Header />
      {/* </nav> */}
    

    </div>
  )
}
