import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-top: 16px solid blue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 25px;
  animation: spin 5s linear infinite;
  animation-name: ${spinAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

export {
  Loader
}