import React from "react";

const BrowserCompatibility = ({ image, video }) => {
  // if not safari Browser, below line  will give false
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      {!isSafari ? (
        <video autoPlay loop muted>
          <source src={video} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={image} alt="" />
      )}
    </>
  );
};

export default BrowserCompatibility;
