import React from "react";
import styled from "styled-components";

const Button = ({ onClick, text }) => {
  return <ButtonStyled onClick={onClick}>{text}</ButtonStyled>;
};

export default Button;

const ButtonStyled = styled.button`
  /* basic button layout */
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 10px;
  font-size: 16px;
  width: 160px;
  cursor: pointer;
  border-radius: 20px;

  /* defines look of button */
  border-color: #5e6ff9;
  color: #5e6ff9;
  border-radius: 20px;

  &:hover {
    background-color: #5e6ff9;
    color: white;
  }
`;
