import React from "react";
import styled from "styled-components";
import { MainContainer } from "../assets/styles/theme/styles";
import Button from "./ Button";
import CodeFramer from "./CodeFramer";
import Heading from "./Heading";

export default function WhatsappDiv6({ heading, data }) {
  return (
    <HeroContainer>
      <MainContainer>
        <FlexContainerWrapper>
          <FlexItem isFirstChild={true}>
            <Heading level={1}>{heading}</Heading>
            {data.map((item) => (
              <Paragraph key={item}>{item}</Paragraph>
            ))}
            <BtnsWrapper>
              <a
                href="https://tubelightcommunications.com/dev-doc/developer.html"
                target="_blank"
              >
                <Button text={"Documentation"} />
              </a>
            </BtnsWrapper>
          </FlexItem>
          <FlexItem>
            <CodeFramer />
          </FlexItem>
        </FlexContainerWrapper>
      </MainContainer>
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  h1 {
    max-width: 805px;
    color: #5e6ff9;
  }
`;

const FlexContainerWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2rem;

  @media (min-width: 1020px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 9rem;
  }
`;

const FlexItem = styled.div`
  &:nth-child(1) {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: ${({ isFirstChild }) =>
      isFirstChild ? "space-around" : "initial"};

    @media (min-width: 1020px) {
      text-align: left;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #595b74;
`;

const GlobeImage = styled.img`
  width: 100%;
  max-width: 350px;
  height: fit-content;
  margin-top: 1.5rem;
  padding: 24px;
`;

const BtnsWrapper = styled.div`
  display: grid;
  place-items: center;

  @media (min-width: 1020px) {
    display: block;
  }
`;
