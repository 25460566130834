import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IMAGES from "../../../Images";
import styles from "./SlideDiv2.module.css";
import { MainContainer } from "../../../assets/styles/theme/styles";
import RedirectToPages from "../../../shared/RedirectToPages";

export default function SlideDiv2() {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 728,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          speed: 4000,
          autoplaySpeed: 4000,
        },
      },
    ],
  };

  return (
    <>
      <div className={styles.image_fluid}>
        <section className={styles.hero_container}>
          <MainContainer>
            {/* <div className={styles.flex_container}> */}
            <div className={styles.text_container}>
              <h1>Conversational AI Powered Customer Engagement Platform</h1>
              {/* <p>Empowering Brands for a Superlative Customer experience</p> */}
              <p>
                Tubelight is a CePaaS company that empowers brands, through its
                Omnichannel communication platform, to engage their customers
                using the channel of their choice.
                <br />{" "}
                <i> Empowering Brands for a Superlative Customer experience.</i>
              </p>
              <br />
              <div className={styles.btns_wrapper}>
                <RedirectToPages href="sign-up">
                  <button className={`${styles.btn} ${styles.success1}`}>
                    Try for free
                  </button>
                </RedirectToPages>
                <RedirectToPages href="contact-us">
                  <button className={`${styles.btn} ${styles.success2}`}>
                    Talk to Expert
                  </button>
                </RedirectToPages>
              </div>
            </div>
          </MainContainer>
          <div className={styles.company_partner}>
            <Slider {...settings}>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo1} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo2} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo3} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo4} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo5} />
              </div>

              <div className={styles.company_logo}>
                <img src={IMAGES.Logo6} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo7} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo8} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo9} />
              </div>
              <div className={styles.company_logo}>
                <img src={IMAGES.Logo10} />
              </div>
            </Slider>
          </div>
          {/* </div> */}
        </section>
      </div>
    </>
  );
}
