import React from 'react'
import styled from 'styled-components';
import theme from '../assets/styles/theme/theme';
import { Link } from 'react-router-dom';
import IMAGES from '../Images';

function Error() {
  return (
    <>    
    <ContentWrapper>
        <h3>
        Sorry, page not found!

        </h3>
        <p>Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.</p>
        <img src={IMAGES.error} alt='not found'/>
        <NavLinks to="/">Go to Home</NavLinks>
    </ContentWrapper>
    </>
  )
}

export default Error;

const ContentWrapper = styled.div`
padding-top: 20px;
padding-bottom: 96px;
max-width: 480px;
margin-left: auto;
margin-right: auto;
display: flex;
// min-height: 90vh;
text-align: center;
align-items: center;
flex-direction: column;
justify-content: center;

h3 {
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0px 0px 24px;
}
p{
    margin: 0px;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
}

img {
    width: 85%;
    height: auto;
}
}
`;

const NavLinks = styled(Link)`
  color: white;
  background-color: ${theme.colors.primary};
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.9375rem;
  text-transform: unset;
  min-width: 64px;
  padding: 8px 22px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  box-shadow: rgba(145, 158, 171, 0.2) 0px 3px 1px -2px, rgba(145, 158, 171, 0.14) 0px 2px 2px 0px, rgba(145, 158, 171, 0.12) 0px 1px 5px 0px;
  min-height: 48px;
  
`;