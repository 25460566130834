import React from "react";
import styles from "./Cellhover.module.css";
import IMAGES from "../../Images";
import { MainContainer } from "../../assets/styles/theme/styles";
import Heading from "../../shared/Heading";
import styled from "styled-components";
import RedirectToPages from "../../shared/RedirectToPages";
import BrowserCompatibility from "../../shared/BrowserCompatibility";

export default function Cellhover() {

  const icons = [
    { src: IMAGES.cellhover1, alt: "WhatsApp", magic: IMAGES.homeMobile },
    { src: IMAGES.cellhover2, alt: "Instagram", magic: IMAGES.homeMobile },
    { src: IMAGES.cellhover3, alt: "Twitter", magic: IMAGES.homeMobile },
    // { src: IMAGES.telegram, alt: "Telegram", magic: IMAGES.homeMobile },
    { src: IMAGES.cellhover4, alt: "Messenger" },
    { src: IMAGES.cellhover5, alt: "Tube" },
    { src: IMAGES.cellhover6, alt: "Facebook" },
    { src: IMAGES.cellhover7, alt: "Google" },
    { src: IMAGES.cellhover8, alt: "In" },
    // { src: IMAGES.telegram, alt: "Telegram" },
    { src: IMAGES.cellhover9, alt: "Call" },
    // { src: IMAGES.line, alt: "Line" },
  ];

  const iconSet1 = icons.slice(0, 3);
  const iconSet2 = icons.slice(3, 6);
  const iconSet3 = icons.slice(6, 9);

  const ChannelIcons = ({ iconset, parentFun }) => {
    return (
      <div className={styles.iconChannel}>
        {iconset.map((icon, index) => (
          <div
            key={index}
            className="icon"
            onMouseEnter={() => parentFun(icon.magic)}
          >
            <DiamondShape>
              <img src={icon.src} alt={icon.alt} />
            </DiamondShape>
          </div>
        ))}
      </div>
    );
  };

  // const handleIconHover = (src) => {
  //   console.log("image", src);
  //   setImage(src);
  // };

  return (
    <>
      {/* <div className={styles.main_container}> */}
      <MainContainer>
        <Heading level={1}>Channels</Heading>
        <p style={{ marginTop: "1rem" }}>
          Reach out, Engage, Enhance across Channels!
        </p>
        <div className={styles.flex_container}>
          <div className={styles.text_container}>
            <ChannelIcons iconset={iconSet1}  />
            <ChannelIcons iconset={iconSet2} />
            <ChannelIcons iconset={iconSet3} />

            <div className={styles.test_container}>
              <p>Select channel and enter mobile number to send test message</p>
              {/* <PhoneInput
                country={"in"}
                enableSearch={true}
                inputStyle={input_style}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              /> */}
              <RedirectToPages href="sign-up">
                <input
                  type="submit"
                  className="input-field"
                  value="Try for Free"
                  style={{ margin: "0px auto", Width: "180px" }}
                ></input>
              </RedirectToPages>
            </div>
          </div>

          <div className={styles.image_column}>
            {/* {image && <img src={image} alt="" />} */}
            <ImageColumn>
              <BrowserCompatibility
                image={IMAGES.cellhoverMain}
                video={IMAGES.cellhoverMainvideo}
              />
            </ImageColumn>
          </div>

          <div>
            <CellhoverH>91%</CellhoverH>
            <p>
              Better Engagement
              <br /> on customer support
            </p>
          </div>
        </div>
      </MainContainer>

      {/* </div> */}
    </>
  );
}

const CellhoverH = styled.h2`
  color: #5e6ff9;
  font-size: 4rem;
  @media (min-width: 720px) {
    font-size: 5rem;
  }
`;

const DiamondShape = styled.div`
  background-color: white;
  transform: rotate(45deg);
  border-radius: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  img {
    width: 57px !important;
    transform: rotate(-45deg);
    border-radius: 25px;

    &:hover {
      // box-shadow: #5e6ff9 0px 3px 8px;
    }
  }
`;

const ImageColumn = styled.div`
video {
  width: 230px;
  @media (min-width: 720px) {
    width: 280px;
  }
`;
