import React from "react";
import IMAGES from "../../Images";
import styles from "./WhyChoose.module.css";
import CenterImage from "../../shared/CenterImage";
import { MainContainer } from "../../assets/styles/theme/styles";
import Heading from "../../shared/Heading";

export default function WhyChoose() {
  const content = [
    {
      title: "SMS",
      logo: IMAGES.SMS,
      description:
        "Get your SMS delivered with powerful bulk SMS or API messages, powered by a global super network of carrier connections.",
    },
    {
      title: "Voice",
      logo: IMAGES.Voice,
      description:
        "Engage with your customer better by simplifying your business process communication using our cloud telephony platform.",
    },
    {
      title: "Conversational AI",
      logo: IMAGES.AI,
      description:
        "Enhance your customer experience by automating the workflow. Automated Chat and Voice bots helps boost engagement (CSAT), solve issue in real time.",
    },
    {
      title: "MuCH",
      logo: IMAGES.Much,
      description:
        "Safe guard your sensitive and financial transactions using Omni Channel authenticated service.",
    },
    {
      title: "WhatsApp",
      logo: IMAGES.Whatsapp,
      description:
        "Scale the reach of your business using network of 2 billion active users on the world’s most popular messenger app in 180 countries.",
    },
    {
      title: "RCS Messaging",
      logo: IMAGES.RCS,
      description:
        "New Rich channel for brands giving life back to regular SMS inbox. Stand out with advanced two-way customer conversations right in customer SMS inboxes.",
    },
  ];

  return (
    <>
      <MainContainer>
        <Heading level={1} style={{ textAlign: "center" }}>
          Every conversation is an opportunity, Connect with your customers on
          channel of their choice
        </Heading>
        <p
          className={styles.uppcase}
          style={{ marginBottom: "2rem", fontSize: "28px" }}
        ></p>
        <CenterImage content={content} fgimage={IMAGES.home5} />
      </MainContainer>
    </>
  );
}
