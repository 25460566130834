import IMAGES from "../Images";
import React from "react";
import styled from "styled-components";
// import { RiCloseLine } from "react-icons/ri"; // Import the close icon
import CustomForm from "./CustomForm";
import Heading from "../shared/Heading";

const DeleteDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogBox = styled.div`
background-color: black;
text-align: center;

@media (min-width: 467px) {
    }
  @media (min-width: 1020px) {
  }
`;

const CloseButton = styled.button`
  border: none;
  height: 44px;
  width: 44px;
  background-color: transparent;

  // right-side aliging the content
  position: absolute;
  top: 0;
  right: 0;


  svg {
    margin: 0px
  }

`;

const HeroContainer = styled.section`
  background-repeat: no-repeat;
  background-size: contain;
//   background-position: center;
  // background-image: url(${IMAGES.gitex});
  background-color: white;
  color: black;
  height: 100%;
  position: relative;
  @media (min-width: 1020px) {
    // background-size: ;
  }
`;

const CustomModalHeader = styled.div`
 position: relative;
`;

const Inboximg = styled.img`
display: block;
margin: auto;
width: 100px;
`;

function LandingPage({ handleCancelDelete, handleDeleteConfirm }) {

  return (
    <div>
      <DeleteDialog>
        <DialogBox>
          <HeroContainer >
            <MainWrapper>
              <CustomModalHeader>
                <Inboximg src={IMAGES.oneinbox} style={{ width: "100px" }} />
                {/* <Heading level={1} style={{ marginBottom: "0px" }}> Let's Talk</Heading> */}
                <CloseButton onClick={handleCancelDelete}>
                  {/* <RiCloseLine size={28} onClick={handleCancelDelete} /> */}
                </CloseButton>
              </CustomModalHeader>

              <CustomForm handleCancelDelete={handleCancelDelete} />
            </MainWrapper>
          </HeroContainer>
        </DialogBox>
      </DeleteDialog>
    </div>
  );
}

export default LandingPage;

const MainWrapper = styled.div`
margin: 0 auto;
  // max-width: 1240px;
  padding: calc(16px + 1.5625vw);
  text-align: center;

  @media (min-width: 1020px) {
    text-align: left;
    // padding: calc(16px + 1.5625vw);
  }  
`;