import React from "react";
import "./Footer.css";
import logo from ".././assets/images/tubelightLogo.png";
import meta from ".././assets/images/Meta.png";
import trust from ".././assets/images/Trust.png";
import facebook from ".././assets/images/Facebook.svg";
import insta from ".././assets/images/Instagram.svg";
import google from ".././assets/images/google-logo.png";
import Linkedin from ".././assets/images/Linkdin.svg";
import { Link } from "react-router-dom";
import Heading from "../shared/Heading";
import { MainContainer } from "../assets/styles/theme/styles";

export default function Footer() {
  return (
    <>
      <MainContainer style={{ marginTop: "3rem" }}>
        {/* <h2 className='f-header '>Start a project now</h2> */}
        <div className="card-wrapper">
          <section className="brand_container">
            <img
              src={logo}
              alt="logo- Tubelight Communications"
              className="brand"
            />
            <div className="meta-container">
            <a
                href="https://www.facebook.com/business/partner-directory/search?solution_type=messaging&platforms=whatsapp&id=7897535253613760&section=overview"
                target="_blank"
              >
              <img
                src={meta}
                alt="logo- Tubelight Communications"
                className="brand"
              />
            </a>
              <img
                src={trust}
                alt="logo- Tubelight Communications"
                className="brand"
              />
            </div>
            <img
              src={google}
              alt="logo- google"
              className="brand"
              style={{ height: "2.1rem", marginRight: "auto" }}
            />
            <div className="media">
              <a
                href="https://www.facebook.com/tubelightcommunications/"
                target="_blank"
              >
                <img
                  src={facebook}
                  alt="logo- facebook"
                  className="media-icon"
                />
              </a>
              {/* <a href="https://twitter.com/tubelight_commu"  target='_blank'><img src={twitter} alt="logo- twitter" className="media-icon"/></a> */}
              <a
                href="https://www.instagram.com/tubelightcommunications/"
                target="_blank"
              >
                <img src={insta} alt="logo- instagram" className="media-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/tubelight-communications-limited/"
                target="_blank"
              >
                <img
                  src={Linkedin}
                  alt="logo- linkedin"
                  className="media-icon"
                />
              </a>
            </div>
          </section>

          <section>
            <ul className="fnt-size link">
              <Heading level={4} style={{ textAlign: "left" }}>
                Products
              </Heading>
              {/* <li><Link  to='/cpaas/#cpaasHome'>Pricing</Link></li> */}
              <li>
                <Link to="/sms">Messaging</Link>
              </li>
              <li>
                <Link to="/voice-broadcast">Voice</Link>
              </li>
              <li>
                <Link to="/numbers">Numbers</Link>
              </li>
              <li>
                <Link to="/botTL">Conversational AI</Link>
              </li>
              <li>
                <Link to="/campaigns">Campaigns</Link>
              </li>
              <li>
                <Link to="/1iNbox">1iNbox</Link>
              </li>
              <li>
                <Link to="/contact-center-suite">Contact Center Suite</Link>
              </li>
            </ul>
          </section>

          <section>
            <ul className="fnt-size link">
              <Heading level={4} style={{ textAlign: "left" }}>
                Resources
              </Heading>
              {/* <li><Link  to='/cpaas/#cpaasHome'>Pricing</Link></li> */}
              <li>
                <Link to={`privacy-policy `}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={`terms-of-use `}>Terms & Conditions</Link>
              </li>
              {/* <li>
                <Link to="/pricing">Pricing</Link>
              </li> */}
            </ul>
          </section>
          <section>
            <ul className="fnt-size link">
              <Heading level={4} style={{ textAlign: "left" }}>
                Company
              </Heading>
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/careers">Career</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/case-studies">Case Studies</Link>
              </li>
            </ul>
          </section>
        </div>

        <span className="sm-card1">
          <a href="tel:+917589475894 "> +91 75894 75894 </a>
          &#124;{" "}
          <a href="mailto:hello@tubelightcommunications.com">
            hello@tubelightcommunications.com
          </a>
        </span>
        <div className="copyright">
          <p>
            © 2024 Copyright Tubelight Communications Ltd. All rights reserved.
          </p>
          {/* <img src={logo} alt="logo- Tubelight Communications" className="brand"/> */}
        </div>
      </MainContainer>
    </>
  );
}