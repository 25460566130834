import React from "react";
import styled from "styled-components";
import LinkComponent from "./Link";
import BrowserCompatibility from "./BrowserCompatibility";

const CenterImage = ({ fgimage, fgvideo, content, video, link }) => {
  const helpContent1 = content.slice(0, 3);
  const helpContent2 = content.slice(3, 6);

  const HelpContent = ({ content }) => (
    <>
      {content.map((item, index) => (
        <FlexContainer2 key={index}>
          <TextWrapper>
            <h3>
              {item.title}
              {item.logo && <img src={item.logo} alt={`${item.title} logo`} />}
            </h3>
            <p>{item.description}</p>
          </TextWrapper>
        </FlexContainer2>
      ))}
    </>
  );

  const Hero = () => (
    <FlexContainer>
      <FlexItem>
        <HelpContent content={helpContent1} />
      </FlexItem>

      <FlexItem>
        {!video && <GlobeImage src={fgimage} alt="" />}

        <VideoContainer>
          {video && (
            // <video autoPlay loop muted>
            //   <source src={fgimage} type="video/webm" />
            //   Your browser does not support the video tag.
            // </video>

            <BrowserCompatibility image={fgimage} video={fgvideo} />
          )}
        </VideoContainer>
      </FlexItem>

      <FlexItem>
        <HelpContent content={helpContent2} />
        {link && (
          <LinkComponent
            text={link}
            href={`https://tubelightcommunications.com/`}
          />
        )}
      </FlexItem>
    </FlexContainer>
  );

  return (
    <>
      <Hero />
    </>
  );
};

export default CenterImage;

const FlexContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 1020px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0px;
  }
`;

const FlexItem = styled.div`
  flex: 1;

  &:nth-child(2) {
    display: grid;
    place-items: center;
    flex: 1.3;
  }

  &:nth-child(1) {
    margin-top: 1rem;
    @media (min-width: 1020px) {
      text-align: right;
    }
  }
  &:nth-child(3) {
    text-align: center;
    margin-top: 1rem;
    @media (min-width: 1020px) {
      text-align: left;
    }

    h3 {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      @media (min-width: 1020px) {
        justify-content: flex-end;
      }
    }
  }

  > p {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 18px;
    text-align: center;

    @media (min-width: 1020px) {
      text-align: left;
    }
  }
`;

const GlobeImage = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    max-width: 373px;
  }

  @media (min-width: 1024px) {
    max-width: none;
  }
`;

const FlexContainer2 = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const TextWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  > p {
    line-height: 1.6em;
    font-size: 15px;
  }

  > h3 {
    color: #5e6ff9;
    display: flex;
    gap: 1rem;
    margin-bottom: 6px;
    justify-content: center;
    flex-direction: row-reverse;

    @media (min-width: 1020px) {
      justify-content: flex-end;
      flex-direction: row;
      font-size: 1.5rem !important;
    }
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
`;

const VideoContainer = styled.div`
  video {
    width: 100%;

    @media (min-width: 720px) {
      width: 580px;
    }
  }
`;
