import styled, { keyframes } from "styled-components";
import theme from "./theme";
import IMAGES from "../../../Images";
import { Link } from "react-router-dom";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.gap || "2rem"};
  justify-content: ${(props) => props.jfycont || "space-between"};

  @media (min-width: 1020px) {
    flex-direction: ${(props) => props.flxdrn || "row"};
  }

  > div {
    flex: ${(props) => props.flex || ""};
  }
`;

const HeroContainer = styled.section`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.imageUrl});

  @media (min-width: 1020px) {
    background-size: ${(props) => (props.bgSize ? props.bgSize : "contain")};
    background-position: center;
    padding: 0px 3rem;
    ${(props) => (props.ht ? props.ht : "auto")};
  }
`;

const MainContainer = styled.div`
  margin: 0 auto;
  // max-width: 1240px;
  padding: calc(16px + 1.5625vw);
  text-align: center;

  @media (min-width: 1020px) {
    text-align: left;
    padding: calc(16px + 1.5625vw) 9rem;
  }
`;

const WeHelpItem = styled.div`
  color: ${theme.colors.secondary};
  text-align: center;

  @media (min-width: 1020px) {
    text-align: left;
  }
`;

const HalfContainer = styled.div`
  max-width: 690px;
`;

const PricingBtn = styled(Link)`
  background-color: ${theme.colors.primary};
  display: inline-block;
  padding: 7px 2.5rem;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: grey;
  margin: 3rem 0px;  
`;

const Divider = styled.span`
&::before {
    content: "";
    display: block;
    width: 1px;
    height: 220px;
    background-color: grey;
  }
`;

export {
  FlexContainer,
  MainContainer,
  HeroContainer,
  WeHelpItem,
  HalfContainer,
  PricingBtn,
  HorizontalLine,
  Divider
};
