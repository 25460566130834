import React from "react";
import styles from "./Homediv2.module.css";
import { MainContainer } from "../../../assets/styles/theme/styles";
import Heading from "../../../shared/Heading";

export default function Homediv2() {
  return (
    <>
      <section className={styles.hero_container}>
        <MainContainer>
          <Heading level={1} txt="left">
            Why Choose Us
          </Heading>
          <p style={{ marginTop: "2rem" }}>
            Simple programmable APIs, detailed analytics, direct carriers
            relationships and outstanding customer support empower you to focus on your business innovation and growth while our Omnichannel Platform handles your business communications.
          </p>

          <div className={styles.wehelp_container}>
            <div className={styles.wehelp_item}>
              <h2>10K</h2>
              <p>SMS Per Second</p>
            </div>
            <div className={styles.wehelp_item}>
              <h2>&#60;300MS</h2>
              <p>Platform Latency</p>
            </div>
            <div className={styles.wehelp_item}>
              <h2>99.99%</h2>
              <p>Infrastructure Uptime</p>
            </div>
            <div className={styles.wehelp_item}>
              <h2>5K</h2>
              <p>Calls Per Second</p>
            </div>
          </div>
        </MainContainer>
      </section>
    </>
  );
}
