const IMAGES = {
  contact1: require("./assets/images/logo6.png"),
  home5: require("./assets/images/Banner4.webp"),
  bottom: require("./assets/images/bottom.png"),
  toparrow: require("./assets/images/toparrow.png"),
  topicon: require("./assets/images/topicon.png"),
  contact: require("./assets/images/Banner7.webp"),
  ContactUS: require("./assets/images/ContactUS.webp"),
  whatsappfg3: require("./assets/images/whatsappfg3.png"),
  SMS: require("./assets/images/SMS.png"),
  RCS: require("./assets/images/RCS.png"),
  Voice: require("./assets/images/Voice.png"),
  AI: require("./assets/images/Ai.png"),
  Much: require("./assets/images/Much.png"),
  muchc1sms: require("./assets/images/muchc1sms.png"),
  muchc2whatsapp: require("./assets/images/muchc2whatsapp.png"),
  muchc3voice: require("./assets/images/muchc3voice.png"),
  muchc4rcs: require("./assets/images/muchc4rcs.png"),
  muchc5email: require("./assets/images/muchc5email.png"),
  Whatsapp: require("./assets/images/Whatsapp.png"),
  Logo1: require("./assets/client-logo/Logo1.webp"),
  Logo2: require("./assets/client-logo/Logo2.webp"),
  Logo3: require("./assets/client-logo/Logo3.webp"),
  Logo4: require("./assets/client-logo/Logo4.webp"),
  Logo5: require("./assets/client-logo/Logo5.webp"),
  Logo6: require("./assets/client-logo/Logo6.webp"),
  Logo7: require("./assets/client-logo/Logo7.webp"),
  Logo8: require("./assets/client-logo/Logo8.webp"),
  Logo9: require("./assets/client-logo/Logo9.webp"),
  Logo10: require("./assets/client-logo/Logo10.webp"),
  voicefg1video: require("./assets/images/voicefg1.webm"),
  voicefg1: require("./assets/images/voicefg1.gif"),
  smsbgdiv1: require("./assets/images/bgcommondiv.webp"),
  smsfgdiv1video: require("./assets/images/sms1.webm"),
  smsfgdiv1: require("./assets/images/sms1.gif"),
  smsc1: require("./assets/images/smsc1.png"),
  smsc2: require("./assets/images/smsc2.png"),
  smsc3: require("./assets/images/smsc3.png"),
  whatsappfgdiv1video: require("./assets/images/whatsappdiv1.webm"),
  whatsappfgdiv1: require("./assets/images/whatsappdiv1.gif"),
  whatsappc1: require("./assets/images/whatsappc1.png"),
  whatsappc2: require("./assets/images/whatsappc2.png"),
  whatsappc3: require("./assets/images/whatsappc3.png"),
  whatsappCommon: require("./assets/images/Common.png"),
  whatsapp1: require("./assets/images/Whatsapp 1.png"),
  whatsapp2: require("./assets/images/Whatsapp 2.png"),
  whatsapp3: require("./assets/images/Whatsapp 3.png"),
  whatsapp4: require("./assets/images/Whatsapp 4.png"),
  whatsapp5: require("./assets/images/Whatsapp 5.png"),
  whatsapp6: require("./assets/images/Whatsapp 6.png"),
  whatsapp7: require("./assets/images/Whatsapp 7.png"),
  voicec1: require("./assets/images/voicec1.png"),
  voicec2: require("./assets/images/voicec2.png"),
  voicec3: require("./assets/images/voicec3.png"),
  gbmfg1video: require("./assets/images/gbmfg1.webm"),
  gbmfg1: require("./assets/images/gbmfg1.gif"),
  gbmfg2video: require("./assets/images/gbmfg2.webm"),
  gbmfg2: require("./assets/images/gbmfg2.gif"),
  gbmi1: require("./assets/images/11.png"),
  gbmi2: require("./assets/images/12.png"),
  gbmi3: require("./assets/images/13.png"),
  gbmi4: require("./assets/images/14.png"),
  gbmi5: require("./assets/images/15.png"),
  gbmi6: require("./assets/images/16.png"),
  gbmi7: require("./assets/images/17.png"),
  gbmi8: require("./assets/images/18.png"),
  rcsfg1video: require("./assets/images/rcsfg1.webm"),
  rcsfg1: require("./assets/images/rcsfg1.gif"),
  rcsfg2: require("./assets/images/rcsfg2.png"),
  rcsc1: require("./assets/images/rcsc1.png"),
  rcsc2: require("./assets/images/rcsc2.png"),
  rcsc3: require("./assets/images/rcsc3.png"),
  emailfg1video: require("./assets/images/emailfg1.webm"),
  emailfg1: require("./assets/images/emailfg1.gif"),
  emailfg2: require("./assets/images/emailfg2.png"),
  emailc1: require("./assets/images/emailc1.png"),
  emailc2: require("./assets/images/emailc2.png"),
  emailc3: require("./assets/images/emailc3.png"),
  bgcommondiv: require("./assets/images/bgcommondiv.webp"),
  flashfg1video: require("./assets/images/flashfg1.webm"),
  flashfg1: require("./assets/images/flashfg1.gif"),
  flashcallvc1: require("./assets/images/flashcallvc1.png"),
  flashcallvc2: require("./assets/images/flashcallvc2.png"),
  flashcallvc3: require("./assets/images/flashcallvc3.png"),
  datavfg1video: require("./assets/images/datavfg1.webm"),
  datavfg1: require("./assets/images/datavfg1.gif"),
  datavfg2video: require("./assets/images/datavfg2.webm"),
  datavfg2: require("./assets/images/datavfg2.gif"),
  datavc1: require("./assets/images/datavc1.png"),
  datavc2: require("./assets/images/datavc2.png"),
  datavc3: require("./assets/images/datavc3.png"),
  smsvc1: require("./assets/images/smsvc1.png"),
  smsvc2: require("./assets/images/smsvc2.png"),
  smsvc3: require("./assets/images/smsvc3.png"),
  smsvfg1video: require("./assets/images/smsvfg1.webm"),
  smsvfg1: require("./assets/images/smsvfg1.gif"),
  smsvfg2: require("./assets/images/smsvfg2.gif"),
  smsvfg2video: require("./assets/images/smsvfg2.webm"),
  muchfg1video: require("./assets/images/muchfg1.webm"),
  muchfg1: require("./assets/images/muchfg1.gif"),
  phonevfg1video: require("./assets/images/phonevfg1.webm"),
  phonevfg1: require("./assets/images/phonevfg1.gif"),
  phonevfg2video: require("./assets/images/phonevfg2.webm"),
  phonevfg2: require("./assets/images/phonevfg2.gif"),
  flowbfg1: require("./assets/images/flowbfg1.png"),
  flowbfg2: require("./assets/images/flowbfg2.png"),
  flowbc1: require("./assets/images/flowbc1.png"),
  flowbc2: require("./assets/images/flowbc2.png"),
  flowbc3: require("./assets/images/flowbc3.png"),
  flywoman: require("./assets/images/flywoman.webp"),
  thinkwoman: require("./assets/images/thinkwoman.webp"),
  chillwoman: require("./assets/images/chillwoman.webp"),
  bg1: require("./assets/images/bg1.webp"),
  bg2: require("./assets/images/bg2.webp"),
  bg3: require("./assets/images/bg3.webp"),
  bg4: require("./assets/images/bg4.webp"),
  ivrfg1video: require("./assets/images/ivrfg1.webm"),
  ivrfg1: require("./assets/images/ivrfg1.gif"),
  ivrc1: require("./assets/images/ivrc1.png"),
  ivrc2: require("./assets/images/ivrc2.png"),
  ivrc3: require("./assets/images/ivrc3.png"),
  IVR1: require("./assets/images/IVR1.png"),
  IVR2: require("./assets/images/IVR2.png"),
  IVR3: require("./assets/images/IVR3.png"),
  IVR4: require("./assets/images/IVR4.png"),
  IVR5: require("./assets/images/IVR5.png"),
  textedufg1video: require("./assets/images/textedufg1.webm"),
  textedufg1: require("./assets/images/textedufg1.gif"),
  texteduc1: require("./assets/images/texteduc1.png"),
  texteduc2: require("./assets/images/texteduc2.png"),
  texteduc3: require("./assets/images/texteduc3.png"),
  bottlfg1: require("./assets/images/bottlfg1.gif"),
  bottlfg1video: require("./assets/images/bottlfg1.webm"),
  aboutc1: require("./assets/images/aboutc1.png"),
  aboutc2: require("./assets/images/aboutc2.png"),
  aboutc3: require("./assets/images/aboutc3.png"),
  Aboutwhyc1: require("./assets/images/Aboutwhyc1.png"),
  Aboutwhyc2: require("./assets/images/Aboutwhyc2.png"),
  Aboutwhyc3: require("./assets/images/Aboutwhyc3.png"),
  singleinboxfg1: require("./assets/images/singleinboxfg1.png"),
  singleinboxfg2: require("./assets/images/singleinboxfg2.png"),
  singleinboxc1: require("./assets/images/singleinboxc1.png"),
  singleinboxc2: require("./assets/images/singleinboxc2.png"),
  singleinboxc3: require("./assets/images/singleinboxc3.png"),
  Campaignerfg1: require("./assets/images/Campaignerfg1.png"),
  campaignc1: require("./assets/images/campaignc1.png"),
  campaignc2: require("./assets/images/campaignc2.png"),
  campaignc3: require("./assets/images/campaignc3.png"),
  contactcenterfg1: require("./assets/images/contactcenterfg1.png"),
  contactcenterc1: require("./assets/images/contactcenterc1.png"),
  contactcenterc2: require("./assets/images/contactcenterc2.png"),
  contactcenterc3: require("./assets/images/contactcenterc3.png"),
  truecallerfg1: require("./assets/images/truecallerfg1.png"),
  truecaller1: require("./assets/images/truecaller1.png"),
  truecaller2: require("./assets/images/truecaller2.png"),
  truecaller3: require("./assets/images/truecaller3.png"),
  truecaller4: require("./assets/images/truecaller4.png"),
  truecaller5: require("./assets/images/truecaller5.png"),
  numbersfg1: require("./assets/images/numbersfg1.png"),
  click2callfg1video: require("./assets/images/click2callfg1.webm"),
  click2callfg1: require("./assets/images/click2callfg1.gif"),
  click2callc1: require("./assets/images/click2callc1.png"),
  click2callc2: require("./assets/images/click2callc2.png"),
  click2callc3: require("./assets/images/click2callc3.png"),
  numberLookupfg1video: require("./assets/images/numberLookupfg1.webm"),
  numberLookupfg1: require("./assets/images/numberLookupfg1.gif"),
  numberMaskingfg1video: require("./assets/images/numberMaskingfg1.webm"),
  numberMaskingfg1: require("./assets/images/numberMaskingfg1.gif"),
  numbermaskingfg2: require("./assets/images/numbermaskingfg2.png"),
  numbermaskingc1: require("./assets/images/numbermaskingc1.png"),
  numbermaskingc2: require("./assets/images/numbermaskingc2.png"),
  numbermaskingc3: require("./assets/images/numbermaskingc3.png"),
  shortcodefg1video: require("./assets/images/shortcodefg1.webm"),
  shortcodefg1: require("./assets/images/shortcodefg1.gif"),
  shortcodec1: require("./assets/images/shortcodec1.png"),
  shortcodec2: require("./assets/images/shortcodec2.png"),
  shortcodec3: require("./assets/images/shortcodec3.png"),
  Partner: require("./assets/images/Partner.webp"),
  WebRTC: require("./assets/images/WebRTC.png"),
  webrtcc1: require("./assets/images/webrtcc1.png"),
  webrtcc2: require("./assets/images/webrtcc2.png"),
  webrtcc3: require("./assets/images/webrtcc3.png"),
  webrtcc4: require("./assets/images/webrtcc4.png"),
  rohufg1: require("./assets/images/rohufg1.png"),
  Career: require("./assets/images/Career.webp"),
  cellhover1: require("./assets/images/cellhover1.png"),
  cellhover2: require("./assets/images/cellhover2.png"),
  cellhover3: require("./assets/images/cellhover3.png"),
  cellhover4: require("./assets/images/cellhover4.png"),
  cellhover5: require("./assets/images/cellhover5.png"),
  cellhover6: require("./assets/images/cellhover6.png"),
  cellhover7: require("./assets/images/cellhover7.png"),
  cellhover8: require("./assets/images/cellhover8.png"),
  cellhover9: require("./assets/images/cellhover9.png"),
  cellhoverMainvideo: require("./assets/images/cellhoverMain.webm"),
  cellhoverMain: require("./assets/images/cellhoverMain.gif"),
  About: require("./assets/images/About-us.webp"),
  error: require("./assets/images/error.png"),
  oneinbox: require("./assets/images/oneInboxLogo.png"),
  check: require("./assets/images/check-mark.png")
};
export default IMAGES;