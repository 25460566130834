import React from "react";
import "./Home.css";
import CardSection from "../shared/CardSection";
import SlideDiv2 from "./components/slidedivs/SlideDiv2";
import Homediv2 from "./components/homediv2/Homediv2";
import WhyChoose from "./components/WhyChoose";
import AutoplayCarousel from "./components/carousel/AutoplayCarousel";
import Cellhover from "./components/Cellhover";
import WhatsappDiv6 from "../shared/WhatsappDiv6";
import IMAGES from "../Images";
import { HeroContainer } from "../assets/styles/theme/styles";
import LandingPage from "../landing-page/LandingPage";

export default function Home(props) {
  // const [isLandinPageVisible, setLandinPageVisible] = useState(true);

  // useEffect(() => {
  //   isLandinPageVisible
  //     ? document.body.classList.add("scroll-lock")
  //     : document.body.classList.remove("scroll-lock");
  // }, [isLandinPageVisible]);


  const arrayData = [
    {
      icon: IMAGES.flywoman,
      heading: "Campaigner",
      paragraph: `Target your customers by automating and designing your intelligent campaign
        strategy on their preferred channels.`,
      linkname: "Learn more",
      linkref: `/campaigns`,
    },
    {
      icon: IMAGES.thinkwoman,
      heading: "1iNbox",
      paragraph: `Omnichannel, one shared inbox. find and share information collaborate, and resolve customer
        queries faster.`,
      linkname: "Learn more",
      linkref: `/1iNbox`,
    },
    {
      icon: IMAGES.chillwoman,
      heading: "Contact Center Suite",
      paragraph: `Omnichannel, customer engagement on one workspace available to teams and agent on the go... `,
      linkname: "Learn more",
      linkref: `/contact-center-suite`,
    },
  ];

 
  const data = [
    "Tubelight programmable API infrastructure enables developers to easily integrate communication API’s and create solutions of their choice. Create a free developer account today to access API and SDK’s.",
    // " Powered with Conversation AI supporting chatbots and voice bots, the platform enables brands to enhance their customer experiences.",
  ];

  // const handleCancelDelete = () => {
  //   setLandinPageVisible(false);
  //   document.body.classList.remove("lock-scroll");
  // };

  return (
    <>
      {/* {isLandinPageVisible && (
        <LandingPage handleCancelDelete={handleCancelDelete}
        />
      )} */}

      <SlideDiv2 />
      <HeroContainer imageUrl={IMAGES.bg1}>
        <Cellhover />
      </HeroContainer>

      <HeroContainer imageUrl={IMAGES.bg1}>
        <Homediv2 />
        <WhyChoose />
      </HeroContainer>

      <HeroContainer imageUrl={IMAGES.bg4}>
        <CardSection
          heading={`Engage & Manage`}
          data={arrayData}
          paragraph={`Tubelight Communications integrates with leading 3rd party  Softwares, e-commerce platforms, CRMs, and marketing tools. Its AI powered omni channel communication platform supports brands to engage & manage their customers conversations to enhance their customer experiences. Try campaigner, 1iNbox, contact center suite to improve your NPS.`}
        />
        <WhatsappDiv6 heading={"For Developers, By Developers"} data={data} />
      </HeroContainer>

    </>
  );
}
