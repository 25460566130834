import React, { useEffect } from "react";
import { useState } from "react";
import "./Header.css";
import logo from "../assets/images/tubelightLogo.png";
import Icons from "./index";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import IMAGES from "./index";

export default function Header() {
  const [checked, setChecked] = useState(true);

  // logic for open/close menu and lock backgound scroll.
  const openMenu = (event) => {
    setChecked(!checked);

    checked
      ? document.getElementsByClassName("menu")[0].classList.add("menu-in")
      : document.getElementsByClassName("menu")[0].classList.remove("menu-in");

    checked
      ? document.body.classList.add("scroll-lock")
      : document.body.classList.remove("scroll-lock");
  };

  // selects all anchor tag(links) and add click event which calls function to close openend menu container.
  const elements = document.getElementsByTagName("a");
  for (var i = 0, len = elements.length; i < len; i++) {
    elements[i].onclick = () => {
      document.getElementsByClassName("btn")[0].classList.add("menu-btn");
      document.getElementsByClassName("menu")[0].classList.remove("menu-in");
      document.getElementsByClassName("btn")[0].classList.remove("close-btn");
      document.body.classList.remove("scroll-lock");
      setChecked(true);
    };
  }

  return (
    <>
      <div className="header-wrapper" id="header">
        <header className="header-container">
          <div className="login-container">
            <span>
              <Link to="sign-up">Signup</Link>
            </span>
            <span>
              <Link to="https://portal.tubelightcommunications.com/sign-in">
                Login
              </Link>
            </span>

            {/* <span>
              <a href="sign-up">STARTUP</a>
              <Link to="sign-up">SIGN UP</Link>
            </span> */}
          </div>
          <Link to="/">
            <img
              src={logo}
              alt="logo- Tubelight Communications"
              className="brand-icon"
            />
          </Link>
          <input
            type="checkbox"
            style={{ display: "none" }}
            checked={checked}
          />
          <label
            className={`btn ${checked ? "menu-btn" : "close-btn"}`}
            onClick={openMenu}
          >
            <span></span>
          </label>

          <nav className="menu" id="clickbox">
            <ol>
              <li className="menu-item active hello">
                <div>
                  <span className="nav-bottom-line">Products</span>
                  <span className="material-icons"></span>
                </div>
                <ol className="sub-menu first-sub-menu ">
                  <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        <span> Messaging</span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div">
                        <Link to="sms">
                          <p>
                            <img src={IMAGES.sms} alt="" />
                            SMS
                          </p>
                          {/* <p className="description">
                            SMS marketing Campaigns that get a real response
                          </p> */}
                        </Link>
                        <HashLink smooth to="/rcs">
                          <img src={IMAGES.rcs} alt="" />
                          <div>
                            <p>RCS</p>
                            {/* <p className="description">
                              Specialized with excellent quality and competitive
                              rates.
                            </p> */}
                          </div>
                        </HashLink>
                        <Link to="/whatsapp">
                          <img src={IMAGES.whatsapp2} alt="" />
                          <div>
                            <p>WhatsApp</p>
                            {/* <p className="description">
                              Reliable business communication for important
                              updates
                            </p> */}
                          </div>
                        </Link>
                        <Link to="/gbm">
                          <img src={IMAGES.gbm} alt="" />
                          <div>
                            <p>GBM</p>
                            {/* <p className="description">
                              Reach your audience at scale with a few clicks
                            </p> */}
                          </div>
                        </Link>
                        <Link to="/email">
                          <img src={IMAGES.email} alt="" />
                          <div>
                            <p>Email</p>
                            {/* <p className="description">
                              Reach your audience at scale with a few clicks
                            </p> */}
                          </div>
                        </Link>
                        <Link to="/shortcode">
                          <img src={IMAGES.Shortcode} alt="" />
                          <div>
                            <p>
                              {/* <img src="" alt="" /> */}
                              Shortcode
                            </p>
                            {/* <p className="description">lorem lorem</p> */}
                          </div>
                        </Link>
                      </div>
                      {/* <div className="right-div">
                        <HashLink smooth to="/sms/#smsSec1">
                          <p>
                            <img src="" alt="" />
                            TextEdu
                          </p>
                          <p className="description">
                            Add protection to your Online Payments
                          </p>
                        </HashLink>
                        <HashLink smooth to="/sms/#smsSec1">
                          <p>
                            <img src="" alt="" />
                            Email to SMS
                          </p>
                          <p className="description">
                            Reach your audience at scale with a few clicks
                          </p>
                        </HashLink>
                      </div> */}
                    </div>
                  </li>
                  <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        <span>Voice </span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div">
                        <Link to="voice-broadcast">
                          <img src={IMAGES.Voicebroadcast} alt="" />
                          <p>Voice Broadcast</p>
                          {/* <p className="description">
                            Automated customer support over interactive voice
                            recognition
                          </p> */}
                        </Link>
                        <Link to="/contact-center-suite">
                          <img src={IMAGES.Contactcentersuite} alt="" />
                          <p>Contact Center Suite</p>
                          {/* <p className="description">
                            Manage your call center seamlessly on a single cloud
                            platform
                          </p> */}
                        </Link>
                        <Link to="/ivr">
                          <img src={IMAGES.IVR} alt="" />
                          <p>IVR</p>
                          {/* <p className="description">
                            Opportunity to save costs on managing their call
                            centers
                          </p> */}
                        </Link>
                        <Link to="/number-masking">
                          <img src={IMAGES.Numbermasking} alt="" />
                          <p>Number Masking</p>
                          {/* <p className="description">
                            Opportunity to save costs on managing their call
                            centers
                          </p> */}
                        </Link>
                        <Link to="/flow-builder">
                          <img src={IMAGES.Flowbuilder} alt="" />
                          <p>Flow Builder</p>
                          {/* <p className="description">
                            Opportunity to save costs on managing their call
                            centers
                          </p> */}
                        </Link>
                        <Link to="/web-rtc">
                          <img src={IMAGES.WebRTC} alt="" />
                          <p>WebRTC</p>
                          {/* <p className="description">
                            Opportunity to save costs on managing their call
                            centers
                          </p> */}
                        </Link>
                        <Link to="/click2call">
                          <img src={IMAGES.Click2call} alt="" />
                          <p>Click2Call</p>
                          {/* <p className="description">
                            Opportunity to save costs on managing their call
                            centers
                          </p> */}
                        </Link>
                      </div>
                      {/* <div className="right-div">
                        <HashLink smooth to="/voice/#voiceSec1">
                          <p>
                            <img src="" alt="" />
                            Missed Call Solution
                          </p>
                          <p className="description">
                            Manage your call center seamlessly on a single cloud
                            platform
                          </p>
                        </HashLink>
                        <HashLink smooth to="/voice/#voiceSec1">
                          <p>
                            <img src="" alt="" />
                            Number Masking
                          </p>
                          <p className="description">
                            Masking your phone numbers is an advanced data
                            protection
                          </p>
                        </HashLink>
                      </div> */}
                    </div>
                  </li>
                  <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        {" "}
                        <span>Numbers & Verification</span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div">
                        <Link to="/number-lookup">
                          <img src={IMAGES.Numberlookup} alt="" />
                          <p>Number Lookup</p>
                          {/* <p className="description">
                            The ultimate one-click collection for customer
                            engagement.
                          </p> */}
                        </Link>
                        <Link to="/numbers">
                          <img src={IMAGES.Numbers} alt="" />
                          <p>Numbers</p>
                          {/* <p className="description">
                            Notify customers in real-time with updates reminders
                            and alerts
                          </p> */}
                        </Link>
                        <Link to="/sms-verification">
                          <img src={IMAGES.SMSverification} alt="" />
                          <p>SMS Verification</p>
                          {/* <p className="description">
                            Rich interactive messaging on your customer's SMS
                          </p> */}
                        </Link>
                        <Link to="/flash-call-verification">
                          <img src={IMAGES.Flashverification} alt="" />
                          <p>Flash Call Verification</p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                        <Link to="/phone-verification">
                          <img src={IMAGES.Phoneverification} alt="" />
                          <p>Phone Verification</p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                        <Link to="/data-verification">
                          <img src={IMAGES.Dataverification} alt="" />
                          <p>Data Verification</p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                        <Link to="/much">
                          <img src={IMAGES.MuCh} alt="" />
                          <p>MuCh</p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                      </div>
                      {/* <div className="right-div">
                        <HashLink smooth to="/cpaas/#cpaasSec1">
                          <p>
                            <img src="" alt="" />
                            Conversational AI
                          </p>
                          <p className="description">
                            The ultimate solution giving you a complete insight
                            of your business.
                          </p>
                        </HashLink>
                        <HashLink smooth to="/cpaas/#cpaasSec1">
                          <p>
                            <img src="" alt="" />
                            CRM Integration
                          </p>
                          <p className="description">
                            Integrate our services easily with your CRM on the
                            go
                          </p>
                        </HashLink>
                        <HashLink smooth to="/cpaas/#cpaasSec1">
                          <p>
                            <img src="" alt="" />
                            AI and ML Driven
                          </p>
                          <p className="description">
                            Bot platform enables businesses to build intelligent
                            chatbot
                          </p>
                        </HashLink>
                      </div> */}
                    </div>
                  </li>
                  {/* <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        <span>Verification</span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div"></div>
                      <div className="right-div">
                        <HashLink smooth to="/whatsapp/#whatsappSec3">
                          <p>
                            <img src="" alt="" />
                            True Caller
                          </p>
                          <p className="description">
                            Adding Safety And Efficiency To Business
                          </p>
                        </HashLink>
                      </div>
                    </div>
                  </li> */}

                  <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        <span to="whatsapp">Engage & Apps</span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div">
                        <Link to="/campaigns">
                          <img src={IMAGES.Campaigns} alt="" />
                          <p>Campaigns</p>
                          {/* <p className="description">
                            Provide your customers with various customer care
                            and notifications
                          </p> */}
                        </Link>
                        <Link to="/1iNbox">
                          <img src={IMAGES.SingleInbox} alt="" />

                          <p>1iNbox</p>
                          {/* <p className="description">
                            Rich interactive messaging on your customer's SMS
                          </p> */}
                        </Link>
                        <Link to="/botTL">
                          <img src={IMAGES.BotTL} alt="" />
                          <p>BotTL</p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                        <Link to="/rohu">
                          <p>
                            <img src={IMAGES.Rohu} alt="" />
                            RoHu
                          </p>
                          {/* <p className="description">
                            Provide your customers with various customer care
                            and notifications
                          </p> */}
                        </Link>
                        <Link to="/truecaller">
                          <p>
                            <img src={IMAGES.Truecaller} alt="" />
                            Truecaller
                          </p>
                          {/* <p className="description">
                            Rich interactive messaging on your customer's SMS
                          </p> */}
                        </Link>
                        <Link to="/textEdu">
                          <p>
                            <img src={IMAGES.TextEdu} alt="" />
                            TextEdu
                          </p>
                          {/* <p className="description">
                            Having a dedicated number for your business
                          </p> */}
                        </Link>
                      </div>
                      {/* <div className="right-div">
                        <HashLink smooth to="/whatsapp/#whatsappSec3">
                          <p>
                            <img src="" alt="" />
                            True Caller
                          </p>
                          <p className="description">
                            Adding Safety And Efficiency To Business
                          </p>
                        </HashLink>
                      </div> */}
                    </div>
                  </li>

                  {/* <li>
                    <div className="drop-icon-flex">
                      <span className="nav--heading">
                        <span>Apps</span>
                      </span>
                      <span className="material-icons sub-material-icons"></span>
                    </div>
                    <div className="sub-main-div superSubMenu ">
                      <div className="left-div"></div>
                      <div className="right-div">
                        <HashLink smooth to="/whatsapp/#whatsappSec3">
                          <p>
                            <img src="" alt="" />
                            True Caller
                          </p>
                          <p className="description">
                            Adding Safety And Efficiency To Business
                          </p>
                        </HashLink>
                      </div>
                    </div>
                  </li> */}
                </ol>
              </li>

              {/* <li className="menu-item">
                <div>
                  <span href="" className="nav-bottom-line">
                    Solutions
                  </span>
                  <span className="material-icons"></span>
                </div>
                <ol className="drop-main sub-menu">
                  <div className="drop-left">
                    <div className="drop-left-heading">By Industry</div>
                    <ul className="drop-left-list-links">
                      <li>
                        <Link to="industry-banks&finance">
                          <p className="description">
                            <img src="" alt="" />
                            Banks and Financial Services
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="industry-education">
                          <p className="description">
                            <img src="" alt="" />
                            Education
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="industry-it">
                          <p className="description">
                            <img src="" alt="" />
                            IT and ITES
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="industry-health">
                          <p className="description">
                            <img src="" alt="" />
                            Healthcare
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="industry-entertainment">
                          <p className="description">
                            <img src="" alt="" />
                            Entertainment
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="travel&hospitality">
                          <p className="description">
                            <img src="" alt="" />
                            Travel and Hospitality
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="drop-right">
                    <div className="drop-right-heading">By Case Studies</div>
                    <ul className="drop-right-list-links">
                      <li>
                        <Link to="banking&financial">
                          <p className="description">
                            <img src="" alt="" />
                            Banking and Financial Services
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="stock-broking">
                          <p className="description">
                            <img src="" alt="" />
                            Stock Broking
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="case-education">
                          <p className="description">
                            <img src="" alt="" />
                            Education
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="case-healthcare">
                          <p className="description">
                            <img src="" alt="" />
                            Health Care
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="real-estate">
                          <p className="description">
                            <img src="" alt="" />
                            Real Estate
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="case-technology">
                          <p className="description">
                            <img src="" alt="" />
                            Technology
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link to="case-transport">
                          <p className="description">
                            <img src="" alt="" />
                            Transport
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ol>
              </li> */}

              {/* <li className="menu-item">
                <div>
                  <span className="nav-bottom-line">Technologies</span>
                  <span className="material-icons"></span>
                </div>
                <ol className="sub-menu">
                  <li>
                    <Link to="conversational">Conversational AI</Link>
                  </li>
                  <li>
                    <Link to="ml-assistant">ML Assistant</Link>
                  </li>
                  <li>
                    <Link to="nlp">Natural Language Processing</Link>
                  </li>
                  <li>
                    <Link to="voice-analytics">Voice Analytics</Link>
                  </li>
                  <li>
                    <Link to="crm">CRM Integrations</Link>
                  </li>
                  <li>
                    <Link to="csat">CSAT Survey & Reports</Link>
                  </li>
                </ol>
              </li> */}

              <li className="menu-item">
                <div>
                  <span className="nav-bottom-line">Developer</span>
                  <span className="material-icons"></span>
                </div>
                <div className="sub-main-div2 superSubMenu ">
                  <div className="left-div">
                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/sms"
                      id="developer_section"
                      target="_blank"
                    >
                      <img src={Icons.DeveloperSMS} alt="" />
                      <div>
                        <p>
                          {/* <img src={Icons.sms2} alt="" style={{width: "20px", margin: "0 8px"}} /> */}
                          SMS
                        </p>
                        <p className="description">
                          Get started SMS APIs using this Doc
                        </p>
                      </div>
                    </a>
                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/voice"
                      target="_blank"
                      className="developer_section"
                    >
                      <img src={Icons.DeveloperVoice} alt="" />
                      <div>
                        <p>Voice</p>
                        <p className="description">
                          This guide shows how to get started using the Voice
                          APIs
                        </p>
                      </div>
                    </a>

                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/email"
                      target="_blank"
                      className="developer_section"
                    >
                      <img src={Icons.DeveloperEmail} alt="" />
                      <div>
                        <p>Email</p>
                        <p className="description">
                          Send Email, View reports, etc using APIs
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="right-div">
                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/whatsapp"
                      target="_blank"
                      className="developer_section"
                    >
                      <img src={Icons.DeveloperWhatsApp} alt="" />
                      <div>
                        <p>WhatsApp</p>
                        <p className="description">
                          Reach your audience using WhatsApp APIs
                        </p>
                      </div>
                    </a>

                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/rcs"
                      target="_blank"
                      className="developer_section"
                    >
                      <img src={Icons.DeveloperRCS} alt="" />
                      <div>
                        <p>RCS</p>
                        <p className="description">
                          Send rich messages using RCS APIs
                        </p>
                      </div>
                    </a>

                    <a
                      href="https://tubelightcommunications.com/dev-doc/developer.html#tag/2FA"
                      target="_blank"
                      className="developer_section"
                    >
                      <img src={Icons.Developer2fa} alt="" />
                      <div>
                        <p>2FA</p>
                        <p className="description">
                          Secure your system using 2FA(Two Factor
                          Authentication)
                        </p>
                      </div>
                    </a>
                  </div>
                  {/* Integrations sections */}
                  <div className="Integrations-div">
                    <p>Integrations</p>
                    <div className="crm-doc-wrapper">
                      <div>
                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Salesforce/Onboard"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={IMAGES.salesforce} alt="" />
                        </a>

                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Zoho/About"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.zoho} alt="" />
                        </a>

                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Leadsquared/Installation"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.leadsquared} alt="" />
                        </a>
                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Freshworks/Onboard"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.freshworks} alt="" />
                        </a>
                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Kylas/About"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.kylas} alt="" />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/WordPressPlugin/Configuration"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.wordpress} alt="" />
                        </a>
                        <a
                          href="https://tubelightcommunications.com/crm-docs/docs/Zendesk/About"
                          className="developer_section"
                          target="_self"
                        >
                          <img src={Icons.zendesk} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="menu-item">
                <div>
                  <span className="nav-bottom-line">
                    <Link to="partner-with-us">Become a Partner</Link>
                  </span>
                </div>
              </li>

              <li className="menu-item">
                <div>
                  <span className="nav-bottom-line">
                    <Link to="contact-us">Contact Us</Link>
                  </span>
                </div>
              </li>

              <li className="menu-item">
                <div>
                  <span href="#0" className="nav-bottom-line">
                    Company
                  </span>
                  <span className="material-icons"></span>
                </div>
                <ol className="sub-menu">
                  <li>
                    <Link to="about">About Us</Link>
                  </li>
                  <li>
                    <Link to="careers">Careers</Link>
                  </li>
                  {/* <li>
                    <Link to="newsroom">Newsroom</Link>
                  </li> */}
                </ol>
              </li>
              <div className="login_container_mobile">
                <li className="menu-item">
                  <div>
                    <span className="nav-bottom-line">
                      <Link to="sign-up" style={{ color: "#5e6ff9" }}>
                        Signup
                      </Link>
                    </span>
                    <span>
                      <Link
                        to="https://portal.tubelightcommunications.com/sign-in"
                        style={{ color: "#5e6ff9" }}
                      >
                        Login
                      </Link>
                    </span>
                  </div>
                </li>
                {/* <li className="menu-item">
                  <div>
                    <span className="nav-bottom-line">
                      <Link to="sign-up" style={{ color: "#5e6ff9" }}>
                        STARTUP
                      </Link>
                    </span>
                  </div>
                </li> */}
              </div>
            </ol>
          </nav>
        </header>
      </div>
    </>
  );
}
