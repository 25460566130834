import React, { Suspense, lazy } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { GlobalStyle } from "./components/assets/styles/globalStyles";
import { HeroContainer } from "./components/assets/styles/theme/styles";
import IMAGES from "./components/Images";
import HideNavbar from "./components/header/HideNavbar";
import SignUp from "./components/signup/SignUp";
import Error from "./components/pages/Error";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";


// Lazy loading components
const Sms = lazy(() => import("./components/sms/Sms"));
const Voice = lazy(() => import("./components/voice/Voice"));
const Gbm = lazy(() => import("./components/cpaas/Gmb"));
const ContactHome = lazy(() => import("./components/contact/ContactHome"));
const WhatsApp = lazy(() => import("./components/whatsapp/whatsapp"));
const Click2Call = lazy(() => import("./components/industry/pages/industry-bank/Click2Call"));
const Rohu = lazy(() => import("./components/industry/pages/industry-hospitality/Rohu"));
const IVR = lazy(() => import("./components/industry/pages/industry-education/IVR"));
const NumberMasking = lazy(() => import("./components/industry/pages/industry-it/NumberMasking"));
const ShortCode = lazy(() => import("./components/components/components/ShortCode"));
const FlowBuilder = lazy(() => import("./components/industry/pages/industry-entertainment/FlowBuilder"));
const RCS = lazy(() => import("./components/technologies/conversational/RCS"));
const Email = lazy(() => import("./components/technologies/ml-assistant/Email"));
const DataVerification = lazy(() => import("./components/technologies/nlp/DataVerification"));
const WebRtc = lazy(() => import("./components/technologies/crm/WebRtc"));
const FlashCallVerification = lazy(() => import("./components/technologies/csat/FlashCallVerification"));
const NumberLook = lazy(() => import("./components/components/NumberLook"));
const Much = lazy(() => import("./components/industry/pages/case-studies/banking/Much"));
const SmsVerification = lazy(() => import("./components/industry/pages/case-studies/education/SmsVerification"));
const PhoneVerification = lazy(() => import("./components/industry/pages/case-studies/verification/PhoneVerification"));
const Truecaller = lazy(() => import("./components/industry/pages/case-studies/real-estate/Truecaller"));
const Numbers = lazy(() => import("./components/industry/pages/case-studies/transport/Numbers"));
const Partner = lazy(() => import("./components/partner/Partner"));
const About = lazy(() => import("./components/company/about/About"));
const Career = lazy(() => import("./components/company/careers/Career"));
const Campaign = lazy(() => import("./components/company/our-clients/Campaign"));
const BotTL = lazy(() => import("./components/company/ContactUs/BotTL"));
const Privacy = lazy(() => import("./components/footer/components/PrivacyPolicy"));
const ContactCenterSuite = lazy(() => import("./components/technologies/voice-analytics/ContactCenterSuite"));
const SingleInbox = lazy(() => import("./components/technologies/voice-analytics/SingleInbox"));
const TextEdu = lazy(() => import("./components/pages/TextEdu"));
const Terms = lazy(() => import("./components/footer/components/Terms"));
const Blogs = lazy(() => import("./components/blogs/listofblogs/Blogs"));
const Article1 = lazy(() => import("./components/blogs/articles/Article1"));
const ContactUS = lazy(() => import("./components/company/ContactUS"));
const ZohoDocs = lazy(() => import("./components/crm-docs/zohodocs/ZohoDocs"));
const CaseStudies = lazy(() => import("./components/blogs/listofblogs/CaseStudies"));
const CaseArticle = lazy(() => import("./components/blogs/articles/CaseArticle"));
const LandingPage = lazy(() => import("./components/landing-page/LandingPage"));
const Pricing = lazy(()=> import("./components/pricing/Pricing"));
const SmsPricing = lazy(()=> import("./components/pricing/pages/SmsPricing"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCancelDelete = () => {
    navigate("/1iNbox");
  };

  // const excludedPaths = [
  //   "partner-with-us",
  //   "terms-of-use",
  //   "privacy-policy",
  //   "zoho-documentation",
  //   "zoho",
  //   "salesforce",
  //   "leadsquared",
  //   "freshworks",
  //   "wordpress-plugin",
  //   "kylas",
  //   "zendesk",
  //   "contact-us",
  // ];

  const excludedPaths = [
    "/",
    "/sms",
    "/voice-broadcast",
    "/gbm",
    "/whatsapp",
    "/click2call",
    "/ivr",
    "/number-masking",
    "/shortcode",
    "/flow-builder",
    "/rcs",
    "/email",
    "/data-verification",
    "/web-rtc",
    "/much",
    "/rohu",
    "/phone-verification",
    "/sms-verification",
    "/truecaller",
    "/number-lookup",
    "/about",
    "/careers",
    "/contact-center-suite",
    "/flash-call-verification",
    "/numbers",
    "/campaigns",
    "/botTL",
    "/textEdu",
    "/1iNbox",
  ];

  const hideContactForm = excludedPaths.some((path) => {
    return location.pathname === path;
  });

  if (window.location.pathname === "/sign-up") {
    return <SignUp />;
  } else {
    return (
      <>
        <GlobalStyle />
        <HideNavbar />

        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/sms" element={<Sms />} />
            <Route path="/voice-broadcast" element={<Voice />} />
            <Route path="/gbm" element={<Gbm />} />
            <Route path="/whatsapp" element={<WhatsApp />} />
            <Route path="/click2call" element={<Click2Call />} />
            <Route path="/ivr" element={<IVR />} />
            <Route path="/number-masking" element={<NumberMasking />} />
            <Route path="/shortcode" element={<ShortCode />} />
            <Route path="/flow-builder" element={<FlowBuilder />} />
            <Route path="/rcs" element={<RCS />} />
            <Route path="/email" element={<Email />} />
            <Route path="/data-verification" element={<DataVerification />} />
            <Route path="/web-rtc" element={<WebRtc />} />
            <Route path="/much" element={<Much />} />
            <Route path="/rohu" element={<Rohu />} />
            <Route path="/phone-verification" element={<PhoneVerification />} />
            <Route path="/sms-verification" element={<SmsVerification />} />
            <Route path="/truecaller" element={<Truecaller />} />
            <Route path="/number-lookup" element={<NumberLook />} />
            <Route path="/about" element={<About />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/contact-center-suite" element={<ContactCenterSuite />} />
            <Route path="/flash-call-verification" element={<FlashCallVerification />} />
            <Route path="/partner-with-us" element={<Partner />} />
            <Route path="/numbers" element={<Numbers />} />
            <Route path="/campaigns" element={<Campaign />} />
            {/* <Route path="/pricing" element={<Pricing />} />
            <Route path="/pricing/sms" element={<SmsPricing />} /> */}
            <Route path="/botTL" element={<BotTL />} />
            <Route path="/textEdu" element={<TextEdu />} />
            <Route path="/contact-us" element={<ContactUS />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/1iNbox" element={<SingleInbox />} />
            <Route
              path="/1iNbox/form"
              element={<LandingPage handleCancelDelete={handleCancelDelete} />}
            />
            <Route path="*" element={<Error />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/zoho-documentation" element={<ZohoDocs />} />
            <Route path="/integrations/zoho" element={<ZohoDocs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:slug" element={<Article1 />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/case-studies/:slug" element={<CaseArticle />} />
          </Routes>
        </Suspense>

        <HeroContainer imageUrl={IMAGES.bg3}>
          {hideContactForm === true ? (
            <ContactHome heading={`Get in touch!`} rightImg={IMAGES.contact} />
          ) : null}
          <Footer />
        </HeroContainer>
      </>
    );
  }
}

export default App;
