import React from "react";
import styled from "styled-components";
import { MainContainer } from "../assets/styles/theme/styles";
import Heading from "./Heading";
import Paragraph from "./Paragraph";
import LinkComponent from "./Link";

export default function CardSection({ heading, data, paragraph }) {
  return (
    <>
      <MainContainer>
        <div>
          <Heading level={1}>{heading}</Heading>
          <p>{paragraph}</p>
          <CardsContainer>
            {data.map((val, index) => {
              return (
                <GridItem key={index}>
                  <img src={val.icon} alt="" />
                  <Heading level={3}>{val.heading}</Heading>
                  <Paragraph level={2} style={{ textAlign: "center" }}>
                    {val.paragraph}
                  </Paragraph>
                  <LinkComponent text={val.linkname} href={val.linkref} />
                </GridItem>
              );
            })}
          </CardsContainer>
        </div>
      </MainContainer>
    </>
  );
}

const CardsContainer = styled.section`
  // max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 20px auto;
  gap: 30px;
  align-items: center;
  justify-content: center;
`;

const GridItem = styled.div`
  background-color: inherit;
  color: #666;
  padding: 15px 20px 20px 20px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  img {
    width: 373px;
  }

  p {
    color: #273746;
    margin-bottom: 0px;
  }
`;

