import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./CodeFramer.module.css";

export default function CodeFramer() {
  const [activeTab, setActiveTab] = useState(0);

  const codeSnippets = [
    {
      language: "php",
      code: `$tubelightCommunications = new \ttubelightCommunications\Client('YOUR_ACCESS_KEY');
            $message = new \ttubelightCommunications\Objects\Message();
            $message->originator = 'tubelightCommunications';
            $message->recipients = [31612345678];
            $message->body = 'This is a test message.';
            $tubelightCommunications->messages->create($message);`,
    },
    {
      language: "java",
      code: `TubelightCommunicationsService service = new TubelightCommunicationsServiceImpl("YOUR_ACCESS_KEY");
            TubelightCommunicationsClient TubelightCommunicationsClient = new TubelightCommunicationsClient(service);
            List<BigInteger> recipients = new ArrayList<>();recipients.add(new BigInteger('31612345678'));
            MessageResponse response = TubelightCommunicationsClient.sendMessage( "tubelightCommunications", "This is a test message.", recipients);
          }`,
    },
    {
      language: "python",
      code: `import TubelightCommunicationsclient = tubelightCommunications.Client('YOUR_ACCESS_KEY')
            message = client.message_create('tubelightCommunications', '31612345678', 'This is a test message.')
            `,
    },
  ];

  const customStyle = {
    maxHeight: "313px",
    overflowY: "overlay",
  };

  const codeTagProps = {
    style: {
      fontSize: "16px",
    },
  };

  return (
    <div className={styles.framer_shadow_container}>
      <div className={styles.framer_container}>
        <Tabs
          selectedIndex={activeTab}
          onSelect={(index) => setActiveTab(index)}
          selectedTabClassName={styles.selected_tab}
        >
          <TabList className={styles.tablist}>
            <Tab className={styles.tab}>PHP</Tab>
            <Tab className={styles.tab}>Java</Tab>
            <Tab className={styles.tab}>Python</Tab>
          </TabList>

          {codeSnippets.map((snippet, index) => (
            <TabPanel key={index}>
              <SyntaxHighlighter
                language={snippet.language}
                style={vscDarkPlus}
                wrapLongLines={true}
                customStyle={customStyle}
                codeTagProps={codeTagProps}
              >
                {snippet.code}
              </SyntaxHighlighter>
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
