import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import "bootstrap/dist/css/bootstrap.min.css";
import IMAGES from "../Images";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import "./CustomForm.css";
import styled from "styled-components";
import { useSearchParams } from 'react-router-dom';

import {
    apiToken,
    mailapiToken,
    sendOtp,
    verifyOtp,
    sendMail,
    sendContact,
} from "../../helpers/dotenv";
import RedirectToPages from "../shared/RedirectToPages";
import { encryptPayload } from "../../helpers/EncryptDecrypt";

export default function CustomForm({ heading, rightImg, mailFrom, handleCancelDelete }) {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [jobprofile, setJobprofile] = useState("");
    const [email, setEmail] = useState("");
    const [otpfirstclick, setOtpfirstclick] = useState(true);
    const [service, setService] = useState("SMS");
    const [msg, setMsg] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [phone, setPhone] = useState("");
    const [verified, setVerified] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [sentMsgBox, setSentMsgBox] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    //params
    const [searchParams] = useSearchParams();
    // Get the value of the "source" parameter
    const source = searchParams.get('source');

    function cleanInputBox() {
        setName("");
        setEmail("");
        setCompany("");
        setMsg("");
        setPhone("");
        setJobprofile("");
        setIsChecked(false);
    }

    function handleResendOTP() {
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), 30000);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${apiToken}`);
        myHeaders.append("Content-Type", "application/json");

        var formdata = {
            phone_number: `+${phone}`,
            email: `${email}`,
            time: Date.now()
        };

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ data: encryptPayload(formdata) }),
            redirect: "follow",
        };

        fetch(sendOtp, requestOptions)
            // .then((response) => response.text())
            .then((response) => {
                //here code will be if send otp show otp box else alert otp not sent.
                if (response.status === 200) {
                    setModalShow(true);
                } else {
                    alert("Invalid Phone number");
                }
            })
            .catch((error) => console.log("error", error));
    }

    function handleClick(e) {
        e.preventDefault();

        if (otpfirstclick) {
            setOtpfirstclick(false);

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Token ${apiToken}`);
            myHeaders.append("Content-Type", "application/json");

            var formdata = {
                phone_number: `+${phone}`,
                email: `${email}`,
                time: Date.now()
            };

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({ data: encryptPayload(formdata) }),
                redirect: "follow",
            };

            fetch(sendOtp, requestOptions)
                // .then((response) => response.text())
                .then((response) => {
                    //here code will be if send otp show otp box else alert otp not sent.
                    if (response.status === 200) {
                        setModalShow(true);
                    } else {
                        alert("Invalid Phone number");
                    }
                })
                .catch((error) => console.log("error", error));

            setIsButtonDisabled(true);
            setTimeout(() => setIsButtonDisabled(false), 30000);
        } else {
            setModalShow(true); // box show.
        }
    }

    function MyVerticallyCenteredModal(props) {
        const [otp, setOtp] = useState("");
        const handleChange = (otp) => setOtp(otp);

        // verify otp on click of button verify, logic here
        function verifyOTP() {
            // const myHeaders = new Headers();
            // myHeaders.append("Authorization", `Token ${apiToken}`);

            // const formdata = new FormData();
            // formdata.append("phone_number", `+${phone}`);
            // formdata.append("email", `${email}`);
            // formdata.append("otp", otp);

            // const requestOptions = {
            //   method: "POST",
            //   headers: myHeaders,
            //   body: formdata,
            //   redirect: "follow",
            // };

            // fetch(verifyOtp, requestOptions)
            //   // .then((response) => response.text())
            //   .then((response) => {
            //     if (response.status === 200) {
            //       //send mail and confirm message sent.
            const myHeaders2 = new Headers();
            // myHeaders2.append("Authorization", `Bearer ${mailapiToken}`);
            myHeaders2.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                otp: parseInt(otp),
                phone_number: `+${phone}`,
                email: `${email}`,
                email_content: {
                    personalizations: [
                        {
                            subject:
                                mailFrom == "partner"
                                    ? "Brand Partnership form"
                                    : `Enquiry 1iNbox ${source ? `|| source: ${source}` : ''}`,
                            to: [
                                {
                                    email: `${sendContact}`,
                                },
                            ],
                        },
                    ],
                    from: {
                        name: `${name}`,
                        email: "no-reply@tubelightcommunications.com",
                    },
                    content: [
                        {
                            type: "text/html",
                            value: `<p><b>Name: ${name} <br/><br /> MobileNo: +${phone} <br /><br /> Email-Id: ${email} <br /><br /> Company: ${company} <br/><br /> Job Profile: ${jobprofile} <br/><br /> Message: ${msg} <br/><br /> ${source ? `source: ${source}` : ''}<b/></p>`,
                        },
                    ],
                }
            });

            const requestOptions2 = {
                method: "POST",
                headers: myHeaders2,
                body: raw,
                // redirect: "follow",
            };

            fetch(sendMail, requestOptions2)
                .then((response) => {
                    if (response.ok) {
                        setSentMsgBox(true);
                        setTimeout(() => {
                            setSentMsgBox(false);
                            handleCancelDelete();
                        }
                            , 4000);
                    } else {
                        alert("Sorry! Something went wrong.")
                    }
                    response.json()
                })
                .then((result) => {
                    // alert(
                    //   "Hi, Thank you for showing your interest in Tubelight Communications Ltd. Your request is acknowledged, and our concerned team will connect with you soon."
                    // );
                    cleanInputBox(); // clean input boxes

                })
                .catch((error) => {
                    console.log("error", error)
                });

            setModalShow(false); // closes react modal window.
            // } else {
            //   // print incorrect otp: try again.
            //   alert("Due to incorrect OTP, verification failed");
            //   // setModalShow(false);
            // }
            // })
            // .catch((error) => console.log("error", error));
            // .then(result => alert(result))

            // console.log('hello', result);
        }

        return (
            <>
                <Modal
                    {...props}
                    size="lg"
                    id="otp-box"
                    aria-labelledby="contained-modal-title-vcenter"
                    contentClassName="otp_card"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-center"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ModalBody>
                            <h2>OTP verification</h2>
                            <p>
                                Please enter the One-Time Code to verify your account.
                                <br />A one time code has been sent to {phone}
                            </p>
                            <h2>
                                <OtpInput
                                    inputStyle={{
                                        // width: "70px",
                                        // height: "50px",
                                        border: "none",
                                        borderBottom: "2px solid grey",
                                        outline: "none",
                                    }}
                                    className="otp_input"
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    separator={<span>&ensp;</span>}
                                />
                            </h2>
                            <ButtonWrapper onClick={verifyOTP}>Verify</ButtonWrapper>
                            {/* <Button onClick={verifyOTP}>verify otp</Button> */}
                            <button
                                className="btn btn-link"
                                onClick={handleResendOTP}
                                disabled={isButtonDisabled}
                            >
                                Resend one-Time Code
                            </button>
                            <p>Entered a wrong number ?</p>
                        </ModalBody>
                    </Modal.Body>
                </Modal>

                <Modal
                    {...props}
                    size="lg"
                    id="sentMsgBox"
                    show={sentMsgBox}
                    aria-labelledby="contained-modal-title-vcenter"
                    contentClassName="email_card"
                    centered
                >
                    <Modal.Body>
                        <EmailCard>
                            <SvgCircle>
                                <svg
                                    width="64px"
                                    height="72px"
                                    viewBox="27 21 64 72"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                    <desc>Created with Sketch.</desc>
                                    <defs>
                                        <polygon
                                            id="path-1"
                                            points="60.9784821 18.4748913 60.9784821 0.0299638385 0.538377293 0.0299638385 0.538377293 18.4748913"
                                        ></polygon>
                                    </defs>
                                    <g
                                        id="Group-12"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        transform="translate(27.000000, 21.000000)"
                                    >
                                        <g id="Group-5">
                                            <g
                                                id="Group-3"
                                                transform="translate(2.262327, 21.615176)"
                                            >
                                                <mask id="mask-2" fill="white">
                                                    <use xlinkHref="#path-1"></use>
                                                </mask>
                                                <g id="Clip-2"></g>
                                                <path
                                                    d="M7.17774177,18.4748913 L54.3387782,18.4748913 C57.9910226,18.4748913 60.9789911,15.7266455 60.9789911,12.3681986 L60.9789911,6.13665655 C60.9789911,2.77820965 57.9910226,0.0299638385 54.3387782,0.0299638385 L7.17774177,0.0299638385 C3.52634582,0.0299638385 0.538377293,2.77820965 0.538377293,6.13665655 L0.538377293,12.3681986 C0.538377293,15.7266455 3.52634582,18.4748913 7.17774177,18.4748913"
                                                    id="Fill-1"
                                                    fill="#59A785"
                                                    mask="url(#mask-2)"
                                                ></path>
                                            </g>
                                            <polygon
                                                id="Fill-4"
                                                fill="#FFFFFF"
                                                transform="translate(31.785111, 30.877531) rotate(-2.000000) translate(-31.785111, -30.877531)"
                                                points="62.0618351 55.9613216 7.2111488 60.3692832 1.50838775 5.79374073 56.3582257 1.38577917"
                                            ></polygon>
                                            <ellipse
                                                id="Oval-3"
                                                fill="#aeb7fc"
                                                opacity="0.216243004"
                                                cx="30.0584472"
                                                cy="21.7657707"
                                                rx="9.95169733"
                                                ry="9.17325562"
                                            ></ellipse>
                                            <g
                                                id="Group-4"
                                                transform="translate(16.959615, 6.479082)"
                                                fill="#7e8bfa"
                                            >
                                                <polygon
                                                    id="Fill-6"
                                                    points="10.7955395 21.7823628 0.11873799 11.3001058 4.25482787 7.73131106 11.0226557 14.3753897 27.414824 1.77635684e-15 31.3261391 3.77891399"
                                                ></polygon>
                                            </g>
                                            <path
                                                d="M4.82347935,67.4368303 L61.2182039,67.4368303 C62.3304205,67.4368303 63.2407243,66.5995595 63.2407243,65.5765753 L63.2407243,31.3865871 C63.2407243,30.3636029 62.3304205,29.5263321 61.2182039,29.5263321 L4.82347935,29.5263321 C3.71126278,29.5263321 2.80095891,30.3636029 2.80095891,31.3865871 L2.80095891,65.5765753 C2.80095891,66.5995595 3.71126278,67.4368303 4.82347935,67.4368303"
                                                id="Fill-8"
                                                fill="#5e6ff9"
                                            ></path>
                                            <path
                                                d="M33.3338063,67.4368303 L61.2181191,67.4368303 C62.3303356,67.4368303 63.2406395,66.5995595 63.2406395,65.5765753 L63.2406395,31.3865871 C63.2406395,30.3636029 62.3303356,29.5263321 61.2181191,29.5263321 L33.3338063,29.5263321 C32.2215897,29.5263321 31.3112859,30.3636029 31.3112859,31.3865871 L31.3112859,65.5765753 C31.3112859,66.5995595 32.2215897,67.4368303 33.3338063,67.4368303"
                                                id="Fill-10"
                                                fill="#6e7df9"
                                            ></path>
                                            <path
                                                d="M29.4284029,33.2640869 C29.4284029,34.2202068 30.2712569,34.9954393 31.3107768,34.9954393 C32.3502968,34.9954393 33.1931508,34.2202068 33.1931508,33.2640869 C33.1931508,32.3079669 32.3502968,31.5327345 31.3107768,31.5327345 C30.2712569,31.5327345 29.4284029,32.3079669 29.4284029,33.2640869"
                                                id="Fill-15"
                                                fill="#FEFEFE"
                                            ></path>
                                            <path
                                                d="M8.45417501,71.5549073 L57.5876779,71.5549073 C60.6969637,71.5549073 63.2412334,69.2147627 63.2412334,66.3549328 L63.2412334,66.3549328 C63.2412334,63.4951029 60.6969637,61.1549584 57.5876779,61.1549584 L8.45417501,61.1549584 C5.34488919,61.1549584 2.80061956,63.4951029 2.80061956,66.3549328 L2.80061956,66.3549328 C2.80061956,69.2147627 5.34488919,71.5549073 8.45417501,71.5549073"
                                                id="Fill-12"
                                                fill="#8e9afa"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </SvgCircle>

                            <SentTextContainer>
                                <h3>Thank You!</h3>
                                <p>
                                    Your request is acknowledged, and our concerned team will
                                    connect with you soon.
                                </p>
                            </SentTextContainer>
                        </EmailCard>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <section className="contact-flex-container">
                {rightImg && (
                    <div className="contact-left-image">
                        <img src={rightImg} alt="" />
                    </div>
                )}

                <div>
                    <div className="contact-form">
                        <form onSubmit={handleClick} style={{ marginTop: "2rem" }}>
                            <input
                                type="text"
                                className="input-field"
                                value={name}
                                placeholder="Full Name"
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                className="input-field"
                                value={company}
                                placeholder="Company"
                                onChange={(e) => setCompany(e.target.value)}
                                required
                            />
                            <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                value={phone}
                                placeholder="Phone"
                                onChange={(phone) => setPhone(phone)}
                            />
                            <input
                                type="text"
                                className="input-field"
                                value={jobprofile}
                                placeholder="Job Profile"
                                onChange={(e) => setJobprofile(e.target.value)}
                                required
                            />

                            <input
                                type="email"
                                className="input-field long-input-container"
                                value={email}
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <textarea
                                id="tbreview"
                                name="tbreview"
                                placeholder="Your Message"
                                value={msg}
                                rows="1"
                                cols="50"
                                onChange={(e) => setMsg(e.target.value)}
                            ></textarea>
                            {/* terms & condition checkbox below */}
                            <TermsCheckBoxWrapper>
                                <div className="check_flex_container">
                                    <input
                                        id="checkbox"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(event) => setIsChecked(event.target.checked)}
                                    />
                                    <p>
                                        I agree to all the{" "}
                                        <RedirectToPages href="terms-of-use" target="_blank">
                                            Terms of Services{" "}
                                        </RedirectToPages>
                                        and{" "}
                                        <RedirectToPages href="privacy-policy" target="_blank">
                                            Privacy Policy
                                        </RedirectToPages>
                                    </p>
                                </div>
                                {/* <div className="check_flex_container">
                                        <input type="checkbox" />
                                        <p>
                                            I want to receive Tubelight communications
                                            news,trends,offers or blogs by email.
                                        </p>
                                    </div> */}
                            </TermsCheckBoxWrapper>

                            <div style={{ width: "fit-content" }} className="submit-btn">
                                <input
                                    type="submit"
                                    id="button"
                                    className="input-field"
                                    value="Send Message"
                                    // title="Check Terms & Services checkbox to enable button"
                                    disabled={!isChecked}
                                ></input>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
        </>
    );
}

const ButtonWrapper = styled.button`
  padding: 10px 127px;
  background-color: #5e6ff9;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 13px;
  margin-top: 1rem;
`;

const TermsCheckBoxWrapper = styled.div`
  grid-column: 1/-1;

  p {
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
  }

  .check_flex_container {
    display: flex;
    align-items: baseline;
    gap: 5px;
    p {
      margin: 0px;
      margin-bottom: 5px;
      text-align: left;
    }
  }
`;

const ModalBody = styled.div`
  text-align: center;
  p {
    font-size: 16px;
  }
  h2 {
    > div {
      justify-content: center;
    }
  }

  .otp_input input {
    width: 50px !important;
    height: 50px;

    @media (min-width: 768px) {
      width: 70px !important;
    }
  }
`;

const SvgCircle = styled.div`
  background-color: #ced3fd;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 18%;
  padding: 9px;
  margin: 0px auto;
`;

const SentTextContainer = styled.div`
  text-align: center;
  h3 {
    font-weight: 900;
    margin: 0px;
    margin-top: 1rem;
    margin-bottom: 2px;
  }
  p {
    font-size: 15px;
  }
`;

const EmailCard = styled.div`
  svg {
    margin: 0px;
  }
`;

