import React from "react";
import styled from "styled-components";
import theme from "../assets/styles/theme/theme";

const Heading = ({ level, color, children, upc, ...rest }) => {
  switch (level) {
    case 1:
      return (
        <H1 color={color} upc={upc} {...rest}>
          {children}
        </H1>
      );
    case 2:
      return (
        <H2 color={color} upc={upc} {...rest}>
          {children}
        </H2>
      );
    case 3:
      return (
        <H3 color={color} upc={upc} {...rest}>
          {children}
        </H3>
      );
    case 4:
      return (
        <H4 color={color} upc={upc} {...rest}>
          {children}
        </H4>
      );
    case 5:
      return (
        <H5 color={color} upc={upc} {...rest}>
          {children}
        </H5>
      );
    default:
      return null;
  }
};

export default Heading;

const commonStyles = `
  // margin-top: 3rem;
  // margin-bottom: 2.5rem;
  font-weight: 500;
  text-align: center;
  @media (min-width: 1020px) {
    text-align: left;
  }
`;

const H1 = styled.h1`
  ${commonStyles}
  margin-bottom: 2rem;
  color: ${(props) => props.color || theme.colors.primary};
  text-transform: ${(props) => (props.upc ? props.upc : "none")};
  font-size: ${theme.fontSizes.heading2};

  @media (min-width: 1020px) {
    font-size: ${theme.fontSizes.heading1};
  }
`;

const H2 = styled.h2`
  ${commonStyles}
  font-size: ${theme.fontSizes.heading2};
  color: ${(props) => props.color || theme.colors.primary};
  text-transform: ${(props) => (props.upc ? props.upc : "none")};
`;

const H3 = styled.h3`
  ${commonStyles}
  font-size: ${theme.fontSizes.heading3};
  color: ${(props) => props.color || theme.colors.primary};
  text-transform: ${(props) => (props.upc ? props.upc : "none")};
`;

const H4 = styled.h4`
  ${commonStyles}
  font-size: ${theme.fontSizes.heading5};
  color: ${(props) => props.color || theme.colors.primary};
  text-transform: ${(props) => (props.upc ? props.upc : "none")};
`;
const H5 = styled.h2`
  ${commonStyles}
  font-size: ${theme.fontSizes.heading6};
  color: ${(props) => props.color || theme.colors.primary};
  text-transform: ${(props) => (props.upc ? props.upc : "none")};
`;

//This is how you can use ...rest argument in above heading function.
{
  /* <Heading level={1} className="main-heading">Main Heading</Heading>
<Heading level={2} id="subheading" style={{ color: 'red' }}>Subheading</Heading>
<Heading level={3} customProp="foo">Section Heading</Heading> */
}
