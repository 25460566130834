const IMAGES = {
  sms: require("../assets/apiIcons/SMS.png"),
  DeveloperSMS: require("../assets/apiIcons/DeveloperSMS.png"),
  DeveloperVoice: require("../assets/apiIcons/DeveloperVoice.png"),
  DeveloperEmail: require("../assets/apiIcons/DeveloperEmail.png"),
  DeveloperWhatsApp: require("../assets/apiIcons/DeveloperWhatsApp.png"),
  DeveloperRCS: require("../assets/apiIcons/DeveloperRCS.png"),
  Developer2fa: require("../assets/apiIcons/Developer2fa.png"),
  whatsapp2: require("../assets/apiIcons/Whatsapp.png"),
  rcs: require("../assets/apiIcons/RCS.png"),
  insta: require("../assets/apiIcons/Instagramlogo.png"),
  gbm: require("../assets/apiIcons/GBM.png"),
  email: require("../assets/apiIcons/Email.png"),
  Shortcode: require("../assets/apiIcons/Shortcode.png"),
  Voicebroadcast: require("../assets/apiIcons/Voicebroadcast.png"),
  Contactcentersuite: require("../assets/apiIcons/Contactcentersuite.png"),
  IVR: require("../assets/apiIcons/IVR.png"),
  Numbermasking: require("../assets/apiIcons/Numbermasking.png"),
  Flowbuilder: require("../assets/apiIcons/Flowbuilder.png"),
  WebRTC: require("../assets/apiIcons/WebRTC.png"),
  Click2call: require("../assets/apiIcons/Click2call.png"),
  Numberlookup: require("../assets/apiIcons/Numberlookup.png"),
  Numbers: require("../assets/apiIcons/Numbers.png"),
  SMSverification: require("../assets/apiIcons/SMSverification.png"),
  Flashverification: require("../assets/apiIcons/Flashverification.png"),
  Phoneverification: require("../assets/apiIcons/Phoneverification.png"),
  Dataverification: require("../assets/apiIcons/Dataverification.png"),
  MuCh: require("../assets/apiIcons/MuCh.png"),
  Campaigns: require("../assets/apiIcons/Campaigns.png"),
  SingleInbox: require("../assets/apiIcons/SingleInbox.png"),
  BotTL: require("../assets/apiIcons/BotTL.png"),
  Rohu: require("../assets/apiIcons/Rohu.png"),
  Truecaller: require("../assets/apiIcons/Truecaller.png"),
  TextEdu: require("../assets/apiIcons/TextEdu.png"),
  salesforce: require("../assets/client-logo/salesforce.webp"),
  zoho: require("../assets/client-logo/zoho.webp"),
  leadsquared: require("../assets/client-logo/leadsquared.webp"),
  kylas: require("../assets/client-logo/kylas.webp"),
  wordpress: require("../assets/client-logo/wordpress.webp"),
  freshworks: require("../assets/client-logo/freshworks.webp"),
  zendesk: require("../assets/client-logo/zendesk.webp"),
};

export default IMAGES;
